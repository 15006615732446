import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import {
  readEquipmentById,
  updateEquipmentById,
} from "../../../../api/Equipment";
import { AlertContext } from "../../../../context/AlertContext";
import EquipmentModel from "../../../../models/Equipment";
import {
  Body,
  ButtonList,
  colorTheme,
  H3,
  HorizontalFlex,
  Link,
  Page,
  StickyHeader,
  VerticalCushion,
} from "../../Styles";
import Button from "../../ui/Button";
import EditableImage from "../../ui/editables/editableImage/EditableImage";
import EditableText from "../../ui/editables/editableText/EditableText";
import LoadingWidget from "../../ui/loadingWidget/LoadingWidget";

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 4vw auto;
`;

const ImageContainer = styled.div`
  min-width: 60%;
  max-width: 70%;

  /* height: 36.9vw; */
  /* max-height: 36.9vw; */
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: ${colorTheme.lightGrey};
  border-radius: 1.2vw;
`;

const BodyContainer = styled.div`
  width: 100%;
  margin: 0 5vw;
  display: flex;
  flex-direction: column;
`;

export default function Equipment(props: RouteComponentProps<{ id: string }>) {
  const [equipment, setEquipment] = useState<EquipmentModel>({});

  const [isLoading, setIsLoading] = useState(false);

  const { alert, setAlert } = useContext(AlertContext);

  useEffect(() => {
    readEquipmentById(props.match.params.id, (response) => {
      if (response) {
        setEquipment(response);
      }
    });
  }, []);

  const saveEquipment = () => {
    setIsLoading(true);
    updateEquipmentById(props.match.params.id, equipment, (response) => {
      if (response) {
        setIsLoading(false);
        setAlert({ visible: true, message: "Succefully saved!" });
      }
    });
  };

  return (
    <Page>
      <Body>
        <VerticalCushion small></VerticalCushion>
        <Link href={"/portal/equipment"}>All Equipment</Link>
        <StickyHeader>
          <H3 width={1000}>Edit equipment</H3>
          <ButtonList>
            <Button
              primary
              text={"Save"}
              onClick={saveEquipment}
              loading={isLoading}
            ></Button>
          </ButtonList>
        </StickyHeader>

        <Container>
          <ImageContainer>
            <EditableImage
              src={equipment.image}
              onFileChange={(url) => {
                if (url) setEquipment({ ...equipment, image: url });
              }}
            ></EditableImage>
          </ImageContainer>
          <BodyContainer>
            <EditableText
              label={"Item Name"}
              weight={600}
              size={1.5}
              placeholder={"Equipment Name"}
              text={equipment.name || ""}
              rows={2}
              onChange={(text) => setEquipment({ ...equipment, name: text })}
            ></EditableText>

            <EditableText
              label={"Item Description"}
              placeholder={"Description"}
              text={equipment.description || ""}
              rows={8}
              size={1.1}
              onChange={(text) =>
                setEquipment({ ...equipment, description: text })
              }
            ></EditableText>

            <EditableText
              label={"Usage Instruction Video Link"}
              placeholder={"Usage Instruction Video Link"}
              text={equipment.instrucionURL || ""}
              rows={2}
              size={1.1}
              onChange={(text) =>
                setEquipment({ ...equipment, instrucionURL: text })
              }
            ></EditableText>

            <EditableText
              label={"Adjustment Instruction Video Link"}
              placeholder={"Adjustment Instruction Video Link"}
              text={equipment.adjustmentInstructionURL || ""}
              rows={2}
              size={1.1}
              onChange={(text) =>
                setEquipment({ ...equipment, adjustmentInstructionURL: text })
              }
            ></EditableText>
          </BodyContainer>
        </Container>
      </Body>
    </Page>
  );
}

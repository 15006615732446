import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import {
  createEquipment,
  readAllEquipment,
  readAllEquipmentOrderByTimestamp,
} from "../../../../api/Equipment";
import EquipmentModel from "../../../../models/Equipment";
import {
  Body,
  ButtonList,
  H2,
  HorizontalFlex,
  Link,
  Page,
  StickyHeader,
  VerticalCushion,
} from "../../Styles";
import AddButton from "../../ui/buttons/addButton/AddButton";
import Equipment from "./Equipment";

const EquipmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  justify-content: space-between;
`;

export default function EquipmentList(
  props: RouteComponentProps<{ id: string }>
) {
  const [equipmentList, setequipmentList] = useState<EquipmentModel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    readAllEquipemntFromCollection();
  }, []);

  const readAllEquipemntFromCollection = () => {
    readAllEquipment((response) => {
      if (response) setequipmentList(response);
    });
  };

  const createNewEquipment = () => {
    setLoading(true);
    createEquipment({}, (response) => {
      props.history.push("editEquipment/" + response.id);
      setLoading(false);
    });
  };

  const [showDetails, setShowDetails] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Page>
      <Body>
        <VerticalCushion small></VerticalCushion>{" "}
        <Link href={"/portal"}>Dashboard</Link>
        <StickyHeader>
          <H2 width={1000}>Equipment</H2>
          <ButtonList>
            <AddButton
              primary
              loading={loading}
              text={"Add Equipment"}
              onClick={createNewEquipment}
            ></AddButton>
          </ButtonList>
        </StickyHeader>
        <EquipmentContainer>
          {equipmentList
            .slice(0)
            .reverse()
            .map((equipment, index) => {
              return (
                <Equipment
                  data={equipment}
                  onEditClick={() =>
                    props.history.push("./editequipment/" + equipment.id)
                  }
                  onDelete={readAllEquipemntFromCollection}
                ></Equipment>
              );
            })}
        </EquipmentContainer>
      </Body>
    </Page>
  );
}

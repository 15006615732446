import { useState } from "react";
import styled from "styled-components";

import { colorTheme } from "../../../../../theme/Styles";
import FileInput from "../../fileInput/FileInput";
import IconWithText from "../../IconWithText/IconWithText";
import RemoveButton from "../../removeButton/RemoveButton";
import editIcon from "./assets/edit_text_icon.svg";

const Container = styled.div<{ width?: string; height?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  min-height: 20vw;
  height: ${(props) => (props.height ? props.height : "auto")};
  overflow: hidden;
`;

const ChangeImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1a6;
`;

const RemoveButtonContainer = styled.div`
  position: absolute;
  top: 2vw;
  right: 2vw;
  border-radius: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: inline-flex;
  background-color: ${colorTheme.extraLightGrey};
  border-radius: 0.4vw;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

interface editableImageProps {
  width?: string;
  height?: string;
  src?: string | null;
  removable?: boolean;
  onFileChange: (url: string | null) => void;
}

export default function EditableImage(props: editableImageProps) {
  const [showFileInput, setShowFileInput] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  return (
    <Container
      width={props.width}
      height={props.height}
      onMouseLeave={() => setShowIcon(false)}
      onMouseEnter={() => setShowIcon(true)}
    >
      {props.src && <Image src={props.src}></Image>}
      {(showIcon || !props.src) && (
        <ChangeImageContainer>
          {props.src && props.removable && (
            <RemoveButtonContainer>
              <RemoveButton
                onRemoveTriggered={() => props.onFileChange("")}
              ></RemoveButton>
            </RemoveButtonContainer>
          )}
          <IconContainer>
            <IconWithText
              iconSrc={editIcon}
              text={props.src ? "Change Image" : "Add Image"}
              onClick={() => setShowFileInput(true)}
            ></IconWithText>
          </IconContainer>
        </ChangeImageContainer>
      )}

      {showFileInput && (
        <FileInput
          show={showFileInput}
          onCloseTrigger={() => setShowFileInput(false)}
          onUpload={(url: string | null) => {
            props.onFileChange(url);
            setShowFileInput(false);
          }}
        ></FileInput>
      )}
    </Container>
  );
}

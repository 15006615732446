import React from "react";

import Button from "../../Button";
import addImage from "./assets/add_icon.svg";

export default function AddButton(props: {
  onClick: () => void;
  text: string;
  primary?: boolean;
  loading?: boolean;
}) {
  return (
    <Button
      loading={props.loading}
      imageSrc={addImage}
      onClick={props.onClick}
      text={props.text}
      primary={props.primary}
    ></Button>
  );
}

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PortalRoutes from "./components/routes/PortalRoutes";
import WebPageRoutes from "./components/routes/WebPageRoutes";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/portal" component={PortalRoutes}></Route>
        <Route path="/" component={WebPageRoutes}></Route>
      </Switch>
    </Router>

    // <Router>
    //   {/* <Route path="/portal/createEvent/:id" exact component={CreateEvent} />
    //   <Route path="/portal/allevents" exact component={AllEvents} />
    //   <Route path="/portal/equipment" exact component={EquipmentList} /> */}

    //   <React.Fragment>
    //     <TopBar></TopBar>

    //     <Route path="/borrow" component={Borrow} />
    //     <Route path="/volunteer" component={Volunteer} />
    //     <Route path="/donate" component={Donate} />
    //     <Route path="/aboutus" component={AboutUs} />
    //     <Route path="/events" component={Events} />
    //     <Route path="/equipment" component={Equipment} />

    //   </React.Fragment>
    // </Router>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import { P } from "../../../theme/Styles";

const AddressLine = styled(P)`
  margin: 0;
  margin-top: 0.2vw;
`;

export default function FormattedAddress(props: {
  text: string;
  center?: boolean;
  strong?: boolean;
  color?: string;
}) {
  return (
    <div>
      {props.text.split(/\r?\n/).map((s, index) => (
        <AddressLine color={props.color} strong={props.strong}>
          {s}
        </AddressLine>
      ))}
    </div>
  );
}

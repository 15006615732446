import React from "react";
import styled from "styled-components";
import { devices } from "../../../theme/devices";
import { colorTheme } from "../../../theme/Styles";

import icon from "./assets/video_icon.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
`;

const Icon = styled.img`
  width: 2vw;
  height: 2vw;
  margin: 0.05vw;
  margin-right: 0.5vw;
  padding: 0.05vw;

  @media ${devices.mobile} {
    width: 6.5vw;
    height: 6.5vw;
    margin-right: 2vw;
    padding: 0.01vw;
  }
`;

const Link = styled.a`
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: 700;
  /* border-bottom: 1px solid ${colorTheme.primary}; */
  color: ${colorTheme.primary};
  text-transform: uppercase;
  margin: 0.8vw 0;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${colorTheme.primary};
  }

  @media ${devices.mobile} {
    font-size: 3.8vw;
    margin: 4vw 1vw;
  }
`;

export default function VideoLink(props: { text: string; href: string }) {
  return (
    <Container>
      <Link href={props.href} target={"_blank"}>
        <Icon src={icon}></Icon>
        {props.text}
      </Link>
    </Container>
  );
}

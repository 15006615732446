import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { infoIDs, readInfoById } from "../../../api/Info";
import { LocationContext, locationIDs } from "../../../context/LocationContext";
import DonationsInfoModel from "../../../models/infoModels/DonationsInfoModel";
import { devices } from "../../../theme/devices";
import {
  ButtonList,
  colorTheme,
  H1,
  H2,
  H3,
  Link,
  P,
  Section,
} from "../../../theme/Styles";
import { VerticalCushion } from "../../portal/Styles";
import PrimaryButton from "../../ui/buttons/PrimaryButton";
import FormattedAddress from "../../ui/formattedAddress/FormattedAddress";
import FormattedP from "../../ui/formattedP/FormattedP";
import {
  HeroContainer,
  HeroImage,
  HeroImageContainer,
  HeroOverviewContainer,
  HeroSection,
  HeroText,
} from "../../ui/heroComponents/HeroStyledComponents";
import LoadingPage from "../../ui/loadingPage/LoadingPage";
import axplogo from "./assets/axp.png";
import heroImage from "./assets/donate_hero.jpg";
import mastercardLogo from "./assets/mastercard.png";
import paypalLogo from "./assets/paypal.png";
import visaLogo from "./assets/visa.png";

const MainDonationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 5vw 0;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const MainDonation = styled.div`
  width: 38vw;
  /* height: 32vw; */
  border: 3px solid ${colorTheme.extraLightGrey};
  padding: 3vw 3vw;
  background-color: ${colorTheme.extraLightGrey};

  @media ${devices.mobile} {
    width: 100%;
    height: auto;
    margin: 5vw 0;
    padding: 8vw;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  margin: 2vw 0;

  @media ${devices.mobile} {
    margin: 8vw 0;
  }
`;

const Logo = styled.img`
  width: 5vw;
  margin: 0 0.8vw;

  @media ${devices.mobile} {
    width: 15vw;
    height: auto;
  }
`;

const OtherDonation = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${colorTheme.extraLightGrey};
  padding: 1vw 2vw;
  margin: 3vw auto;

  @media ${devices.mobile} {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 5vw 0vw;
    margin-bottom: 10vw;
  }
`;

const OtherDonationImageContainer = styled.div`
  width: 25vw;
  margin: 0 2vw;
  @media ${devices.mobile} {
    width: 90%;
    height: auto;
    margin: 8vw auto;
  }
`;
const OtherDonationImage = styled.img`
  width: 100%;
  height: auto;
`;

const OtherDonationText = styled.div`
  width: 40vw;
  margin: 0vw 2vw;

  @media ${devices.mobile} {
    width: 80%;
    margin: auto;
  }
`;

const DonationOverviewContainer = styled(HeroOverviewContainer)`
  width: 28vw;
  @media ${devices.mobile} {
    width: 100%;
    margin: auto;
  }
`;

export default function Donate() {
  const [donationInfo, setDonationInfo] = useState<DonationsInfoModel | null>(
    null
  );

  const { location, setLocation } = useContext(LocationContext);

  useEffect(() => {
    setLocation(locationIDs.donate);
  }, []);

  useEffect(() => {
    readInfoById(infoIDs.donationsInfo, (response) => {
      if (response) setDonationInfo(response);
    });
  }, []);

  if (!donationInfo) return <LoadingPage></LoadingPage>;
  return (
    <div>
      <HeroSection backgroundColor={colorTheme.extraLightGrey}>
        <HeroContainer reverseOnMobile>
          <HeroText>
            <H1 color={colorTheme.secondary}>Donate</H1>

            {donationInfo.overview && (
              <DonationOverviewContainer>
                <FormattedP text={donationInfo.overview}></FormattedP>
              </DonationOverviewContainer>
            )}
          </HeroText>
          <HeroImageContainer>
            <HeroImage src={heroImage}></HeroImage>
          </HeroImageContainer>
        </HeroContainer>
      </HeroSection>

      <Section>
        <VerticalCushion small>
          {donationInfo.callForDonations && (
            <H2 leftOnMobile color={colorTheme.primary} width={50}>
              {donationInfo.callForDonations}
            </H2>
          )}
        </VerticalCushion>

        <MainDonationContainer>
          {donationInfo.paymentGatewayURL && (
            <MainDonation>
              <H3>Donate by credit card</H3>
              <P>
                We accept Mastercard, Visa and American Express credit cards.
                You can also use your PayPal account.{" "}
              </P>
              <LogoContainer>
                <Logo src={mastercardLogo}></Logo>
                <Logo src={visaLogo}></Logo>
                <Logo src={axplogo}></Logo>
                <Logo src={paypalLogo}></Logo>
              </LogoContainer>
              <ButtonList smallBottomMargin>
                <PrimaryButton
                  text={"Donate Now"}
                  onClick={() => window.open(donationInfo.paymentGatewayURL)}
                ></PrimaryButton>
              </ButtonList>
            </MainDonation>
          )}
          {donationInfo.address && (
            <MainDonation>
              <H3>Send us a check</H3>
              <P>
                You can forward us a check of any amount, payable to “Southwest
                Lending Closet”.
              </P>
              <P>Our Address:</P>
              <FormattedAddress
                strong
                text={donationInfo.address}
              ></FormattedAddress>
            </MainDonation>
          )}
        </MainDonationContainer>
      </Section>

      <Section backgroundColor={colorTheme.white}>
        <H3 color={colorTheme.primary}>Other ways to help our mission</H3>

        {donationInfo.otherMethods &&
          donationInfo.otherMethods.map((block, index) => {
            return (
              <OtherDonation>
                {block.imageSrc && (
                  <OtherDonationImageContainer>
                    <OtherDonationImage
                      src={block.imageSrc}
                    ></OtherDonationImage>
                  </OtherDonationImageContainer>
                )}

                <OtherDonationText>
                  <P strong>{block.header}</P>
                  {block.description && (
                    <FormattedP text={block.description}></FormattedP>
                  )}
                  {block.links && (
                    <ButtonList smallTopMargin smallBottomMargin>
                      {block.links.map((link, indes) => {
                        return <Link href={link.url}>{link.linkText}</Link>;
                      })}
                    </ButtonList>
                  )}
                </OtherDonationText>
              </OtherDonation>
            );
          })}
      </Section>
      {/* <FullWidthHeroImage src={thankyouImage}></FullWidthHeroImage> */}
    </div>
  );
}

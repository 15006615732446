import React from "react";
import styled from "styled-components";
import { colorTheme, P, H3 } from "../../Styles";

const Container = styled.div<{ small?: boolean }>`
  width: 20vw;
  /* height: 10vw; */
  /* border: 1px solid ${colorTheme.black}; */
  border-radius: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.4s;
  margin: 0vw 2vw;
  margin-bottom: 3vw;

  img {
    width: 10vw;
  }

  &:hover {
    transform: scale(1.02);
  }
`;

export default function Card(props: {
  iconSrc: string;
  text: string;
  onClick: () => void;
  small?: boolean;
}) {
  return (
    <Container small={props.small} onClick={props.onClick}>
      <img src={props.iconSrc}></img>
      <P marginTop={1} strong>
        {props.text}
      </P>
    </Container>
  );
}

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import EquipmentModel from "../../../models/Equipment";
import { devices } from "../../../theme/devices";
import { animationKeyFrames } from "../../../theme/keyframes";
import { ButtonList, colorTheme, P } from "../../../theme/Styles";
import SecondaryButton from "../../ui/buttons/SecondaryButton";
import VideoLink from "../../ui/buttons/VideoLink";

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  overflow: hidden;
  background-color: none;
  animation: ${animationKeyFrames.fadeIn} 0.5s;
  margin: 1vw 0;
  cursor: pointer;
  transition: transform 0.2s;
  z-index: 1;

  &:hover {
    transform: scale(1.02);
  }

  @media ${devices.mobile} {
    width: 100%;
    margin: 10vw 0;
  }
`;

const ItemImageContainer = styled.div`
  width: 100%;
  height: 25vw;
  background-color: ${colorTheme.white};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${devices.mobile} {
    max-height: none;
    height: auto;
    margin-bottom: 2vw;
  }
`;

const ModalItemImageContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: ${colorTheme.lightGrey};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalBodyContainer = styled.div`
  display: flex;
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const ItemDetails = styled.div`
  width: 50vw;
  padding: 0 3vw;
  @media ${devices.mobile} {
    width: 100%;
    padding: 4vw 4vw;
  }
`;

const ItemName = styled(P)`
  font-size: 1.5vw;
  font-weight: 800;

  @media ${devices.mobile} {
    font-size: 6.5vw;
  }
`;

const ItemImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 27vw;

  @media ${devices.mobile} {
    max-height: none;
  }
`;

const ModalItemImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 80vh;
`;

const VideoLinkContainer = styled.div`
  margin-top: 3vw;
`;

export default function SingleItem(props: {
  data: EquipmentModel;
  onHowToClick: () => void;
}) {
  const [show, setShow] = useState(false);
  return (
    <Item>
      <ItemImageContainer onClick={() => setShow(true)}>
        <ItemImage src={props.data.image}></ItemImage>
      </ItemImageContainer>
      <P centerOnMobile strong>
        {props.data.name}
      </P>

      <Modal centered size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ModalBodyContainer>
            <ModalItemImageContainer>
              <ModalItemImage src={props.data.image}></ModalItemImage>
            </ModalItemImageContainer>
            <ItemDetails>
              {" "}
              <ItemName strong>{props.data.name}</ItemName>
              <P>{props.data.description}</P>
              <VideoLinkContainer>
                {props.data.instrucionURL && (
                  <VideoLink
                    text={"Usage Instructions"}
                    href={props.data.instrucionURL}
                  ></VideoLink>
                )}
                {props.data.adjustmentInstructionURL && (
                  <VideoLink
                    text={"Adjustment Instructions"}
                    href={props.data.adjustmentInstructionURL}
                  ></VideoLink>
                )}
              </VideoLinkContainer>
              <ButtonList smallBottomMargin>
                <SecondaryButton
                  text={"Learn how to borrow"}
                  onClick={() => {
                    setShow(false);
                    props.onHowToClick();
                  }}
                ></SecondaryButton>
              </ButtonList>
            </ItemDetails>
          </ModalBodyContainer>
        </Modal.Body>
      </Modal>
    </Item>
  );
}

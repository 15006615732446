import React, { useState } from "react";
import styled from "styled-components";
import { colorTheme } from "../../../../theme/Styles";
import PrimaryButton from "../../../ui/buttons/PrimaryButton";
import Button from "../Button";
import Icon from "../Icon";

import deleteIcon from "./assets/delete_icon.svg";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > div {
    margin-left: 0.7vw;
  }
`;

export default function DeleteButton(props: { onDeleteTriggered: () => void }) {
  const [deleteClicked, setDeleteClicked] = useState(false);
  return (
    <Container>
      {!deleteClicked && (
        <Icon
          backgroundColor={colorTheme.primaryLight}
          src={deleteIcon}
          onClick={() => setDeleteClicked(true)}
        ></Icon>
      )}
      {deleteClicked && (
        <Button
          primary
          small
          text={"Yes, Delete"}
          onClick={() => {
            setDeleteClicked(false);
            props.onDeleteTriggered();
          }}
        ></Button>
      )}

      {deleteClicked && (
        <Button
          primary
          small
          text={"No, Cancel"}
          onClick={() => setDeleteClicked(false)}
        ></Button>
      )}
    </Container>
  );
}

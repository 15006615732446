import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { readAllEquipmentOrderByName } from "../../../api/Equipment";
import { LocationContext, locationIDs } from "../../../context/LocationContext";
import EquipmentModel from "../../../models/Equipment";
import { animationKeyFrames } from "../../../theme/keyframes";
import { H1, Section } from "../../../theme/Styles";
import LoadingPage from "../../ui/loadingPage/LoadingPage";
import SingleItem from "./Item";

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  transition: height 0.5s;
  animation: ${animationKeyFrames.fadeIn} 0.5s;
  justify-content: space-between;
  margin: 5vw 0;
`;

export default function Equipment(props: RouteComponentProps<any>) {
  const [equipmentList, setEquipmentList] = useState<EquipmentModel[] | null>(
    null
  );

  const { location, setLocation } = useContext(LocationContext);

  useEffect(() => {
    setLocation(locationIDs.equipment);
  }, []);

  useEffect(() => {
    readAllEquipmentOrderByName((response) => {
      if (response) setEquipmentList(response);
    });
  }, []);

  if (!equipmentList) return <LoadingPage></LoadingPage>;
  return (
    <Section>
      <H1>Equipment List</H1>
      <ItemContainer>
        {equipmentList
          .filter((i) => i.isPublished)
          .map((item) => {
            return (
              <SingleItem
                data={item}
                onHowToClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("borrow");
                }}
              ></SingleItem>
            );
          })}
      </ItemContainer>
    </Section>
  );
}

import { addToCollection, deleteDocumentById, readAllDocuments } from '../firestoreDB/FirestoreDB';
import EquipmentModel from '../models/Equipment';
import { readAllDocumentsOrderBy, readDocumentById, updateDocumentById } from './../firestoreDB/FirestoreDB';

import firebaseInstance from '../firebaseConfig/Firebase'


const EQUIPMENT_COLLECTION = "equipment"

export const createEquipment = (data: EquipmentModel, callback: (docRef: any) => void) => {
    addToCollection(EQUIPMENT_COLLECTION, data, callback)
}

export const updateEquipmentById = (id: string, data: EquipmentModel, callback: (response: any) => void) => {
        var dataWithTimeStamp = {...data, created: firebaseInstance.firestore.Timestamp.now() }

    updateDocumentById(EQUIPMENT_COLLECTION, id, dataWithTimeStamp, callback);
}

export const deleteEquipmentById = (id: string, callback: (response: any) => void) => {
    deleteDocumentById(EQUIPMENT_COLLECTION, id, callback);
}

export const readEquipmentById = (id: string, callback: (response: any) => void) => {
    readDocumentById(EQUIPMENT_COLLECTION, id, callback)
}

export const readAllEquipment = (callback: (response: any) => void) => {
    readAllDocuments(EQUIPMENT_COLLECTION, callback)
}


export const readAllEquipmentOrderByName = (callback: (response: any) => void) => {
    readAllDocumentsOrderBy(EQUIPMENT_COLLECTION, "name", callback)
}

export const readAllEquipmentOrderByTimestamp = (callback: (response: any) => void) => {
    readAllDocumentsOrderBy(EQUIPMENT_COLLECTION, "created", callback)
}
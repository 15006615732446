import React from "react";
import styled from "styled-components";
import { devices } from "../../../theme/devices";

const Image = styled.img`
  height: 30vw;
  border-radius: 100vw;
  margin: 0 auto;
  text-align: center;

  @media ${devices.mobile} {
    width: 100%;
    height: auto;
    border-radius: 0vw;
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 30vw;
  border-radius: 100vw;
  margin: 3vw auto;
  margin-top: 3vw;
  overflow: hidden;

  @media ${devices.mobile} {
    width: 100%;
    border-radius: 0;
    height: auto;
    margin: 0;
  }
`;

export default function CircleImage(props: { src: string }) {
  return (
    <ImageContainer>
      <Image src={props.src}></Image>
    </ImageContainer>
  );
}

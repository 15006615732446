import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { readAllEvents } from "../../../api/Events";
import { LocationContext, locationIDs } from "../../../context/LocationContext";
import Event from "../../../models/Event";
import { devices } from "../../../theme/devices";
import {
  ButtonList,
  colorTheme,
  H1,
  H2,
  H3,
  P,
  Section,
} from "../../../theme/Styles";
import SecondaryButton from "../../ui/buttons/SecondaryButton";
import LoadingPage from "../../ui/loadingPage/LoadingPage";

const UpcomingEvent = styled.div`
  width: 100%;
  display: flex;
  margin: 5vw 0;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const UpcomingEventContainer = styled.div`
  margin: 5vw 0;
`;

const UpcomingEventImageContainer = styled.div`
  width: 50vw;
  height: 30vw;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  @media ${devices.mobile} {
    width: 100%;
    height: auto;
    margin-bottom: 10vw;
  }
  img {
    min-width: 50vw;
    /* height: 100%; */
  }
`;

const EventBody = styled.div`
  width: 50vw;
  margin: 0 5vw;

  p {
    margin: 1vw 0;
  }

  @media ${devices.mobile} {
    width: 100%;
    height: auto;
    margin: 5vw 0;

    p {
      margin: 1vw 0;
    }
  }
`;

const Break = styled.div`
  width: 25vw;
  height: 0.5vw;
  background-color: ${colorTheme.secondary};
`;

const DateLocationContainer = styled.div`
  width: 100%;
  /* background-color: ${colorTheme.primary}; */
  margin: auto;
  position: relative;

  @media ${devices.mobile} {
    margin-top: 7vw;
  }
`;

const DateLocationText = styled(P)`
  font-size: 1.3vw;
  margin: 0;

  @media ${devices.mobile} {
    font-size: 5vw;
    margin: 2vw 0;
  }
`;

export default function Events(props: RouteComponentProps<any>) {
  const [events, setEvents] = useState<Event[] | null>(null);

  const { location, setLocation } = useContext(LocationContext);

  useEffect(() => {
    setLocation(locationIDs.events);
  }, []);

  useEffect(() => {
    readAllEvents((response) => {
      if (response) {
        setEvents(response);
      }
    });
  }, []);

  if (!events) return <LoadingPage></LoadingPage>;
  return (
    <Section>
      <H1>Events</H1>
      <UpcomingEventContainer>
        <H2 leftOnMobile color={colorTheme.secondary}>
          Upcoming events
        </H2>

        {events.map((event, index) => {
          if (event.isPublished && !event.isPastEvent)
            return (
              <UpcomingEvent>
                {event.heroImage && (
                  <UpcomingEventImageContainer>
                    <img src={event.heroImage}></img>
                  </UpcomingEventImageContainer>
                )}
                <EventBody>
                  {event.title && <H3>{event.title}</H3>}
                  {event.description && <P width={30}>{event.description}</P>}
                  <DateLocationContainer>
                    {event.date && (
                      <DateLocationText strong width={30}>
                        {event.date}
                      </DateLocationText>
                    )}
                    {event.location && (
                      <DateLocationText strong width={30}>
                        {event.location}
                      </DateLocationText>
                    )}
                  </DateLocationContainer>
                  <ButtonList>
                    <SecondaryButton
                      text={"More about this event"}
                      onClick={() => {
                        props.history.push("/" + event.id);
                      }}
                    ></SecondaryButton>
                  </ButtonList>
                </EventBody>
              </UpcomingEvent>
            );
        })}
      </UpcomingEventContainer>

      <Break></Break>

      <UpcomingEventContainer>
        <H2 leftOnMobile color={colorTheme.primary}>
          Past events
        </H2>

        {events.map((event, index) => {
          if (event.isPublished && event.isPastEvent)
            return (
              <UpcomingEvent>
                {event.heroImage && (
                  <UpcomingEventImageContainer>
                    <img src={event.heroImage}></img>
                  </UpcomingEventImageContainer>
                )}
                <EventBody>
                  {event.title && <H3>{event.title}</H3>}
                  {event.description && <P width={30}>{event.description}</P>}
                  <DateLocationContainer>
                    {event.date && (
                      <DateLocationText strong width={30}>
                        {event.date}
                      </DateLocationText>
                    )}
                    {event.location && (
                      <DateLocationText strong width={30}>
                        {event.location}
                      </DateLocationText>
                    )}
                  </DateLocationContainer>
                  <ButtonList>
                    <SecondaryButton
                      text={"More about this event"}
                      onClick={() => {
                        props.history.push("/" + event.id);
                      }}
                    ></SecondaryButton>
                  </ButtonList>
                </EventBody>
              </UpcomingEvent>
            );
        })}
      </UpcomingEventContainer>
    </Section>
  );
}

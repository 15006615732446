import React from "react";
import styled from "styled-components";
import { colorTheme, P } from "../../Styles";
import Icon from "../Icon";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;

  padding: 0.4vw 0.6vw;
  border-radius: 0.5vw;

  &:hover {
    background-color: ${colorTheme.extraLightGrey};
  }
`;

const Text = styled(P)<{ small?: boolean }>`
  margin: 0 0.4vw;
  padding: 0;
  font-size: 1vw;
  font-weight: ${(props) => (props.small ? 400 : 700)};
  color: ${colorTheme.black};

  /* border-bottom: 1px solid ${colorTheme.black}; */
`;

export default function IconWithText(props: {
  iconSrc?: string;
  text: string;
  small?: boolean;
  onClick: () => void;
}) {
  return (
    <div>
      <Container onClick={() => props.onClick()}>
        {props.iconSrc && (
          <Icon src={props.iconSrc} onClick={() => props.onClick()}></Icon>
        )}
        <Text small={props.small}>{props.text}</Text>
      </Container>
    </div>
  );
}

import { keyframes } from "styled-components";

export const animationKeyFrames = {
  slideFromBottom: keyframes`
    from {
        transform: translateY(20px);
    }
    to {
        transform: translateY(0);
    }
  `,

  growFromTop: keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100px);
    }
  `,

  heartBeat: keyframes`
    10% {transform: rotate(-15deg)}
    20% {transform: rotate(30deg) scale(1.5)}
    30% {transform: rotate(-15deg)}
    60% {transform: rotate(0) scale(1)}
  `,

  appear: keyframes`
    from {
        transform:scale(0.5)
    }
    to {
      transform:scale(2)
    }
  `,

  fadeIn: keyframes`
    from {
        opacity:0.5
    }
    to {
      opacity:1
    }
  `,
};

import React from "react";
import styled from "styled-components";
import FAQBlockModel from "../../../models/blockModels/FAQBlock";
import { devices } from "../../../theme/devices";
import { P } from "../../../theme/Styles";

const Question = styled(P)`
  margin: 0;
  margin-top: 3vw;
  font-size: 1.4vw;
  @media ${devices.mobile} {
    margin-top: 8vw;
    font-size: 5.5vw;
  }
`;

const Answer = styled(P)`
  margin: 0;
  margin-top: 0.4vw;
  @media ${devices.mobile} {
    margin-top: 2vw;
  }
`;

export default function FAQ(props: { data: FAQBlockModel }) {
  if (props.data.question && props.data.answer)
    return (
      <div>
        <Question strong width={50}>
          {props.data.question}
        </Question>
        <Answer width={50}>{props.data.answer}</Answer>
      </div>
    );

  return null;
}

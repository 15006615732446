import React, { useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import styled from "styled-components";
import { submitVolunteerRequest } from "../../../api/VolunteerRequests";
import VolunteerRequestModel from "../../../models/VolunteerRequestModal";
import { devices } from "../../../theme/devices";
import { ButtonList, H3, P } from "../../../theme/Styles";
import Button from "../../portal/ui/Button";
import PrimaryButton from "../../ui/buttons/PrimaryButton";
import { TextInput } from "../../ui/input/textInput/TextInput";
import { formFields } from "./VolunteerFields";

const InputItem = styled.div`
  margin: auto;
  width: 25vw;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const InputLabel = styled(P)`
  font-size: 1.2vw;
  margin: 0.3vw 0.2vw;
  font-weight: 600;

  @media ${devices.mobile} {
    width: 100%;
    font-size: 4.2vw;
    margin: 0.3vw 0.2vw;
    font-weight: 500;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 3vw auto;
`;

const InputBody = styled.div``;

const MessageBody = styled.div``;

export default function RegistrationModel(props: {
  show: boolean;
  onHide: () => void;
}) {
  const [volunteerRequest, setVolunterRequest] =
    useState<VolunteerRequestModel>({});

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(0);

  const onSubmit = () => {
    if (
      volunteerRequest.firstName &&
      volunteerRequest.lastName &&
      volunteerRequest.email &&
      volunteerRequest.phoneNumber
    ) {
      setLoading(true);
      submitVolunteerRequest(
        {
          to: "hello@swlendingcloset.org",
          // to: "piyum@thexdstudio.com",
          message: {
            subject: "New Volunteer Request",
            html:
              "<code>" +
              "Name: " +
              volunteerRequest.firstName +
              " " +
              volunteerRequest.lastName +
              "<br></br>" +
              "Address: " +
              (volunteerRequest.streedAddress || "-") +
              ", " +
              (volunteerRequest.city || "-") +
              ", " +
              (volunteerRequest.state || "-") +
              ", " +
              (volunteerRequest.zipcode || "-") +
              "<br></br>" +
              "Phone Number: " +
              volunteerRequest.phoneNumber +
              "<br></br>" +
              "Email: " +
              volunteerRequest.email +
              "<br></br>" +
              "Comments: " +
              (volunteerRequest.comments || "-") +
              "</code>",
          },
        },
        (response) => {
          // alert(JSON.stringify(response));
          setLoading(false);
          setShowAlert(response != null ? 1 : -1);
        }
      );
    } else {
      setError(true);
    }
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <H3>Become a front desk volunteer</H3>
      </Modal.Header>
      <Modal.Body>
        {showAlert > 0 && (
          <MessageBody>
            <Alert variant="success" onClose={() => {}}>
              <Alert.Heading>Thank you!</Alert.Heading>
              <p>
                We will review your information and send you an email with more
                details.
              </p>
            </Alert>
          </MessageBody>
        )}
        {showAlert < 0 && (
          <MessageBody>
            <Alert variant="danger" onClose={() => {}}>
              <Alert.Heading>Oops!</Alert.Heading>
              <p>Something went wrong. Please try again later.</p>
            </Alert>
          </MessageBody>
        )}
        {!showAlert && (
          <InputBody>
            <InputItem>
              <InputLabel>First Name</InputLabel>
              <TextInput
                error={error && !volunteerRequest.firstName}
                rows={1}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    firstName: ev.target.value,
                  })
                }
              >
                {volunteerRequest.firstName || ""}
              </TextInput>
            </InputItem>
            <InputItem>
              <InputLabel>Last Name</InputLabel>
              <TextInput
                error={error && !volunteerRequest.lastName}
                rows={1}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    lastName: ev.target.value,
                  })
                }
              >
                {volunteerRequest.lastName || ""}
              </TextInput>
            </InputItem>

            <br></br>

            <InputItem>
              <InputLabel>Street Address</InputLabel>
              <TextInput
                rows={1}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    streedAddress: ev.target.value,
                  })
                }
              >
                {volunteerRequest.streedAddress || ""}
              </TextInput>
            </InputItem>

            <InputItem>
              <InputLabel>City</InputLabel>
              <TextInput
                rows={1}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    city: ev.target.value,
                  })
                }
              >
                {volunteerRequest.city || ""}
              </TextInput>
            </InputItem>

            <InputItem>
              <InputLabel>State</InputLabel>
              <TextInput
                rows={1}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    state: ev.target.value,
                  })
                }
              >
                {volunteerRequest.state || ""}
              </TextInput>
            </InputItem>

            <InputItem>
              <InputLabel>Zip Code</InputLabel>
              <TextInput
                rows={1}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    zipcode: ev.target.value,
                  })
                }
              >
                {volunteerRequest.zipcode || ""}
              </TextInput>
            </InputItem>

            <br></br>
            <InputItem>
              <InputLabel>Phone Number</InputLabel>
              <TextInput
                error={error && !volunteerRequest.phoneNumber}
                rows={1}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    phoneNumber: ev.target.value,
                  })
                }
              >
                {volunteerRequest.phoneNumber || ""}
              </TextInput>
            </InputItem>

            <InputItem>
              <InputLabel>Email</InputLabel>
              <TextInput
                error={error && !volunteerRequest.email}
                rows={1}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    email: ev.target.value,
                  })
                }
              >
                {volunteerRequest.email || ""}
              </TextInput>
            </InputItem>

            <InputItem>
              <InputLabel>Comments / Questions</InputLabel>
              <TextInput
                rows={4}
                onChange={(ev) =>
                  setVolunterRequest({
                    ...volunteerRequest,
                    comments: ev.target.value,
                  })
                }
              >
                {volunteerRequest.comments || ""}
              </TextInput>
            </InputItem>
            {error &&
              !(
                volunteerRequest.firstName &&
                volunteerRequest.lastName &&
                volunteerRequest.email &&
                volunteerRequest.phoneNumber
              ) && (
                <MessageBody>
                  <Alert variant="danger" onClose={() => {}}>
                    <p>Some of the important information is missing!</p>
                  </Alert>
                </MessageBody>
              )}
            <ButtonList>
              <PrimaryButton
                onClick={() => onSubmit()}
                text={"Submit"}
              ></PrimaryButton>
            </ButtonList>
          </InputBody>
        )}
      </Modal.Body>
    </Modal>
  );
}

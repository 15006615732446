import styled from "styled-components";
import { devices } from "../../theme/devices";

export const colorTheme = {
  primary: "#214175",
  primaryLight: "#17cef7",
  primaryMedium: "#0893d3",
  secondary: "#8acec3",
  secondaryLight: "#FFAD86",
  accent: "#F78F1E",
  accentLight: "#F78F1E",
  white: "#ffffff",
  black: "#082332",
  lightGrey: "#F1F2F2",
  extraLightGrey: "#F0F5ED",
  darkGrey: "#B2B2B2",
};

export const H = styled.h1<{
  color?: string;
  width?: number;
  center?: boolean;
}>`
  font-family: "Poppins", sans-serif;
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: 1.2;
  color: ${(props) => props.color || colorTheme.black};
  width: ${(props) => (props.width ? props.width : 30)}vw;

  @media ${devices.mobile} {
    font-size: 9.5vw;
    margin: 10vw 0;
    text-align: center;
    width: 100%;
  }
`;

export const H1 = styled(H)<{}>`
  font-size: 4.5vw;
  font-weight: 700;
  @media ${devices.mobile} {
    font-size: 9.5vw;
  }
`;

export const H2 = styled(H)<{}>`
  font-size: 2.5vw;
  font-weight: 700;
  @media ${devices.mobile} {
    font-size: 9.5vw;
  }
`;

export const H3 = styled(H)<{}>`
  font-size: 1.8vw;
  font-weight: 700;
  @media ${devices.mobile} {
    font-size: 9.5vw;
  }
`;

export const P = styled.p<{
  color?: string;
  width?: number;
  center?: boolean;
  marginTop?: number;
  strong?: boolean;
}>`
  font-family: "Poppins", sans-serif;
  font-size: 1.3vw;
  font-weight: ${(props) => (props.strong ? 700 : 500)};
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: 1.5;
  margin: ${(props) => props.marginTop || 2.5}vw 0;
  color: ${(props) => props.color || colorTheme.black};
  width: ${(props) => (props.width ? props.width + "vw" : "auto")};
  /* max-width: ${(props) => props.width || 100}vw; */

  @media ${devices.mobile} {
    width: 100%;
    font-size: 5vw;
    font-weight: ${(props) => (props.strong ? 700 : 400)};
    br {
      display: none;
    }
  }
`;

export const Section = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${(props) => props.backgroundColor || colorTheme.white};
  padding: 4vw 8vw;

  @media ${devices.mobile} {
    padding: 10vw 7vw;
  }
`;

export const Centered = styled.div`
  width: 100%;
  @media ${devices.mobile} {
    align-items: none;
  }
`;

export const VerticalCushion = styled.div<{ small?: boolean }>`
  padding: ${(props) => (props.small ? 2 : 5)}vw 0;
`;

export const Link = styled.a`
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-size: 1.1vw;
  font-weight: 500;
  /* border-bottom: 1px solid ${colorTheme.black}; */
  color: ${colorTheme.black};
  /* text-transform: uppercase; */
  text-decoration: underline;
  margin-bottom: 2vw;
  margin-right: 3vw;
  cursor: pointer;
  &:hover {
    font-weight: 700;
    color: ${colorTheme.black};
  }

  @media ${devices.mobile} {
    font-size: 4.2vw;
    margin-bottom: 5vw;
  }
`;

export const Page = styled.div`
  width: 100%;
  margin: auto;
`;
export const Body = styled.div`
  width: 90%;
  margin: auto;
`;

export const HorizontalFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StickyHeader = styled(HorizontalFlex)`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: ${colorTheme.white};
  height: 7vw;
  /* border-bottom: 1px solid ${colorTheme.lightGrey}; */
`;

export const ButtonList = styled(HorizontalFlex)<{
  start?: boolean;
  end?: boolean;
  center?: boolean;
}>`
  justify-content: ${(props) =>
    props.end ? "flex-end" : props.center ? "center" : "flex-start"};
  flex-wrap: wrap;
  align-items: center;

  @media ${devices.mobile} {
    justify-content: center;
    flex-direction: column;
    justify-content: ${(props) =>
      props.end ? "center" : props.center ? "center" : "center"};
    margin-top: 15vw;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  margin: 3vw auto;

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const TextArea = styled.textarea`
  font-family: "Poppins", sans-serif;
`;

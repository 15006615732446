import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { readAllEvents } from "../../../api/Events";
import { devices } from "../../../theme/devices";
import { ButtonList, colorTheme, H3, P } from "../../../theme/Styles";
import Event from "../../../models/Event";
import SecondaryButton from "../../ui/buttons/SecondaryButton";

const Container = styled.div`
  padding: 5vw;
  background-color: ${colorTheme.extraLightGrey};
  margin: 0;

  @media ${devices.mobile} {
    padding: 0;
  }
`;

const UpcomingEvent = styled.div`
  width: 100%;
  display: flex;
  /* margin: 5vw 0; */
  align-items: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const UpcomingEventContainer = styled.div`
  margin: 5vw 0;
`;

const UpcomingEventImageContainer = styled.div`
  width: 60vw;
  height: 25vw;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  @media ${devices.mobile} {
    width: 100%;
    height: auto;
    margin-bottom: 5vw;
  }
  img {
    min-width: 50vw;
    /* height: 100%; */
  }
`;

const EventBody = styled.div`
  width: 55vw;
  margin: 0 6.2vw;

  @media ${devices.mobile} {
    height: auto;
    width: 80%;
    margin: 0 10vw;
  }
`;

const Break = styled.div`
  width: 25vw;
  height: 0.5vw;
  background-color: ${colorTheme.secondary};
`;

const Title = styled(H3)`
  margin: 1vw 0;

  @media ${devices.mobile} {
    margin: 2vw 0;
  }
`;

const Description = styled(P)`
  @media ${devices.mobile} {
    margin: 1vw;
  }
`;

const DateLocationContainer = styled.div`
  width: 100%;
  /* background-color: ${colorTheme.primary}; */
  margin: auto;
  position: relative;
  margin-top: 2vw;

  @media ${devices.mobile} {
    margin-top: 7vw;
  }
`;

const DateLocationText = styled(P)`
  font-size: 1.2vw;
  margin: 0.5vw 0;

  @media ${devices.mobile} {
    font-size: 5vw;
    margin: 4vw 0;
  }
`;

export default function FeaturedEvent() {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    readAllEvents((response) => {
      if (response) {
        setEvents(response);
      }
    });
  }, []);

  return (
    <>
      {events.map((event, index) => {
        if (event.isFeatured)
          return (
            <Container>
              <UpcomingEvent>
                {event.heroImage && (
                  <UpcomingEventImageContainer>
                    <img src={event.heroImage}></img>
                  </UpcomingEventImageContainer>
                )}
                <EventBody>
                  {event.title && <Title>{event.title}</Title>}
                  {event.description && (
                    <Description width={40}>{event.description}</Description>
                  )}
                  <DateLocationContainer>
                    {event.date && (
                      <DateLocationText strong width={40}>
                        {event.date}
                      </DateLocationText>
                    )}
                    {event.location && (
                      <DateLocationText strong width={40}>
                        {event.location}
                      </DateLocationText>
                    )}
                  </DateLocationContainer>
                  <ButtonList smallTopMargin>
                    <SecondaryButton
                      backgroundColor={colorTheme.extraLightGrey}
                      text={"More about this event"}
                      onClick={() => {
                        // props.history.push("/" + event.id);
                      }}
                    ></SecondaryButton>
                  </ButtonList>
                </EventBody>
              </UpcomingEvent>
            </Container>
          );
      })}
    </>
  );
}

import { readDocumentById, updateDocumentById, readAllDocuments, deleteDocumentById } from './../firestoreDB/FirestoreDB';
import { addToCollection } from "../firestoreDB/FirestoreDB"
import Event from '../models/Event';

const EVENTS_COLLECTION = "events"

export const createEvent = (data: Event, callback: (docRef: any) => void) => {
    addToCollection(EVENTS_COLLECTION, data, callback)
}

export const updateEventById = (id: string, data: Event, callback: (response: any) => void) => {
    updateDocumentById(EVENTS_COLLECTION, id, data, callback);
}

export const deleteEventById = (id: string, callback: (response: any) => void) => {
    deleteDocumentById(EVENTS_COLLECTION, id, callback);
}

export const readEventById = (id: string, callback: (response: any) => void) => {
    readDocumentById(EVENTS_COLLECTION, id, callback)
}

export const readAllEvents = (callback: (response: any) => void) => {
    readAllDocuments(EVENTS_COLLECTION, callback)
}
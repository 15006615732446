import React from "react";
import styled from "styled-components";
import { devices } from "../../../theme/devices";
import { P } from "../../../theme/Styles";

const Item = styled(P)`
  margin: 0;
  margin-top: 0.2vw;
  @media ${devices.mobile} {
    margin-bottom: 3vw;
  }
`;

const ListHeader = styled(P)`
  margin: 0.2vw 0;
  margin-top: 2vw;

  @media ${devices.mobile} {
    margin-top: 8vw;
  }
`;

export default function FormattedList(props: {
  text: string;
  center?: boolean;
  strong?: boolean;
  listHeader?: string;
  color?: string;
}) {
  return (
    <div>
      {props.listHeader && <ListHeader strong>{props.listHeader}</ListHeader>}
      {props.text.split(/\r?\n/).map((s, index) => (
        <Item color={props.color}>{s}</Item>
      ))}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useInterval } from "../../../../hooks/UseInterval";
import { devices } from "../../../../theme/devices";
import { colorTheme } from "../../../../theme/Styles";

const Container = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const DotWrapper = styled.div`
  width: 60%;
  /* margin: 50vh auto; */
  display: flex;
  justify-content: space-around;
  @media ${devices.mobile} {
    width: 30vw;
    height: 3vw;
  }
`;

const Dot = styled.div<{ selected: boolean }>`
  width: ${(props) => (props.selected ? 0.7 : 0.7)}vw;
  height: ${(props) => (props.selected ? 0.7 : 0.7)}vw;
  background-color: ${(props) =>
    props.selected ? colorTheme.black : colorTheme.white};
  border-radius: 5vw;
  @media ${devices.mobile} {
    width: ${(props) => (props.selected ? 2.2 : 2)}vw;
    height: ${(props) => (props.selected ? 2.2 : 2)}vw;
  }
`;

export default function LoadingWidget() {
  var intervelRef;
  const DOT_COUNT = 4;

  let [count, setCount] = useState(0);

  useInterval(() => {
    // Your custom logic heres
    setCount(count + 1);
  }, 300);

  const renderLoadingWidgetDots = () => {
    let dots = [];
    let selected = false;
    let loadingIndex = count % DOT_COUNT;
    for (let i = 0; i < DOT_COUNT; i++) {
      selected = i == loadingIndex;
      dots.push(<Dot selected={selected}></Dot>);
    }
    return dots;
    //return <p>{count}</p>;
  };

  return (
    <Container>
      <DotWrapper>{renderLoadingWidgetDots()}</DotWrapper>
    </Container>
  );
}

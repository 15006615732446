import { UserContext } from './../context/UserContext';
import firebaseInstance from '../firebaseConfig/Firebase'
import { useContext } from 'react';


export const getCurrentUser = () => { 
return firebaseInstance.auth().currentUser;
}

export const isAuthenticated = () => { 
return firebaseInstance.auth().currentUser?true:false;
}
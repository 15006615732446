import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonList, colorTheme, P, VerticalCushion } from "../../Styles";
import Button from "../../ui/Button";
import EditableText from "../../ui/editables/editableText/EditableText";

import firebaseInstance from "../../../../firebaseConfig/Firebase";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../../../firebaseAuth/FirebaseAuth";
import { Redirect } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";

const Container = styled.div`
  width: 35vw;
  height: auto;
  margin: 8vw auto;
  padding: 3vw;
  padding-bottom: 1vw;
  border-radius: 1vw;
  border: 1px solid ${colorTheme.darkGrey};
`;

const TextInput = styled.input`
  width: 100%;
  margin-bottom: 2vw;
  padding: 1vw;
  border-radius: 0.6vw;
  border: none;
  outline: none;
  background-color: ${colorTheme.extraLightGrey};
`;

const Label = styled(P)`
  margin: 0.4vw 0.2vw;
  font-size: 1.1vw;
  font-weight: 700;
`;

const Error = styled(P)`
  margin: 0vw;
  font-size: 1vw;
  font-weight: 600;
  color: ${colorTheme.accent};
`;

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const signIn = () => {
    setError("");
    setLoading(true);
    firebaseInstance
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        setLoading(false);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setError(errorMessage);
        setLoading(false);
      });
  };

  if (user) {
    return <Redirect to="/portal" />;
  }

  return (
    <Container>
      <Label>{"Email"}</Label>
      <TextInput
        value={email}
        onChange={(ev) => setEmail(ev.target.value)}
      ></TextInput>

      <Label>{"Password"}</Label>
      <TextInput
        type="password"
        value={password}
        onChange={(ev) => setPassword(ev.target.value)}
      ></TextInput>

      <Error>{error}</Error>

      <VerticalCushion small>
        <ButtonList end>
          <Button
            loading={loading}
            primary
            text={"Log in"}
            onClick={signIn}
          ></Button>
        </ButtonList>
      </VerticalCushion>
    </Container>
  );
}

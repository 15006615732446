import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import {
  deleteEquipmentById,
  updateEquipmentById,
} from "../../../../api/Equipment";
import { AlertContext } from "../../../../context/AlertContext";
import EquipmentModel from "../../../../models/Equipment";
import { animationKeyFrames } from "../../../../theme/keyframes";
import { ButtonList, colorTheme, HorizontalFlex, P } from "../../Styles";
import Button from "../../ui/Button";
import DeleteButton from "../../ui/deleteButton/DeleteButton";
import ToggleSwitch from "../../ui/toggleSwitch/ToggleSwitch";

const Container = styled.div`
  width: 30%;
  /* background-color: ${colorTheme.extraLightGrey}; */
  /* display: flex; */
  margin: 4vw 0;
  overflow: hidden;
  border-radius: 1.5vw;
  border: 1px solid ${colorTheme.darkGrey};
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 25vw;
  display: flex;
  align-items: center;
  background-color: ${colorTheme.lightGrey};
  overflow: hidden;
  animation: ${animationKeyFrames.fadeIn} 1s;

  img {
    width: 100%;
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  margin: 2vw;
`;

const ButtonContainer = styled.div`
  width: 85%;
  margin-top: 3vw;
  margin-bottom: 2vw;

  display: flex;
  justify-content: space-between;
`;

export default function Equipment(props: {
  data: EquipmentModel;
  onEditClick: () => void;
  onDelete: () => void;
}) {
  const { alert, setAlert } = useContext(AlertContext);

  const saveEquipment = (eq: EquipmentModel) => {
    if (eq.id)
      updateEquipmentById(eq.id, eq, (response) => {
        if (response) setAlert({ visible: true, message: response.message });
        props.onDelete();
      });
  };

  const onPublish = (ev: React.ChangeEvent<HTMLInputElement>) => {
    var eq = { ...props.data, isPublished: ev.target.checked };
    //setEquipment(eq);
    saveEquipment(eq);
  };

  const deleteItem = (id: string) => {
    deleteEquipmentById(id, (response) => {
      if (response) {
        props.onDelete();
        alert(JSON.stringify(response));
      }
    });
  };

  return (
    <Container>
      <ImageContainer>
        <img src={props.data.image}></img>
      </ImageContainer>
      <BodyContainer>
        <P strong>{props.data.name}</P>

        <ToggleSwitch
          label={"Publish"}
          isChecked={props.data.isPublished || false}
          onChange={onPublish}
        ></ToggleSwitch>
        <ButtonContainer>
          <Button small text={"Edit"} onClick={props.onEditClick}></Button>
          <DeleteButton
            onDeleteTriggered={() => deleteItem(props.data.id || "")}
          ></DeleteButton>
        </ButtonContainer>
      </BodyContainer>
    </Container>
  );
}

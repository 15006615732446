import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/auth'


 var firebaseConfig = {
    apiKey: "AIzaSyAM_9-1sGbqxKpUrILl8oOaC1w4eEuUpps",
    authDomain: "swlc-f3346.firebaseapp.com",
    projectId: "swlc-f3346",
    storageBucket: "swlc-f3346.appspot.com",
    messagingSenderId: "472572602069",
    appId: "1:472572602069:web:4fa6547b3763512a9ce45c",
    measurementId: "G-S0N7CEG0EP"
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  // firebase.analytics();
  
export default firebase;
import React from "react";
import Switch from "@material-ui/core/Switch";
import styled from "styled-components";
import { colorTheme, HorizontalFlex, P } from "../../Styles";
import { withStyles } from "@material-ui/core/styles";

const Container = styled(HorizontalFlex)`
  justify-content: flex-start;
`;

const Text = styled(P)<{ small?: boolean }>`
  margin: 0.5vw 0.1vw;
  padding: 0;
  font-size: 0.9vw;
  font-weight: 400;
  color: ${colorTheme.black};

  /* border-bottom: 1px solid ${colorTheme.black}; */
`;

const PurpleSwitch = withStyles({
  switchBase: {
    color: colorTheme.white,
    "&$checked": {
      color: colorTheme.primaryMedium,
    },
    "&$checked + $track": {
      backgroundColor: colorTheme.primaryLight,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function ToggleSwitch(props: {
  isChecked: boolean;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}) {
  return (
    <Container>
      <PurpleSwitch
        checked={props.isChecked}
        onChange={props.onChange}
        color="primary"
      ></PurpleSwitch>
      {props.label && <Text>{props.label}</Text>}
    </Container>
  );
}

import React, { useState } from "react";
import styled from "styled-components";

import Button from "../Button";
import Icon from "../Icon";
import removeIcon from "./assets/remove_icon.svg";

const Container = styled.div`
  display: flex;
  width: auto;
  & > div {
    margin-left: 1vw;
  }
`;

export default function RemoveButton(props: { onRemoveTriggered: () => void }) {
  const [removeClicked, setRemoveClicked] = useState(false);
  return (
    <Container>
      {!removeClicked && (
        <Icon src={removeIcon} onClick={() => setRemoveClicked(true)}></Icon>
      )}
      {removeClicked && (
        <Button
          small
          text={"Yes, Remove"}
          onClick={() => {
            setRemoveClicked(false);
            props.onRemoveTriggered();
          }}
        ></Button>
      )}

      {removeClicked && (
        <Button
          small
          text={"No, Cancel"}
          onClick={() => setRemoveClicked(false)}
        ></Button>
      )}
    </Container>
  );
}

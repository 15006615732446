import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import {
  readAllEquipment,
  readAllEquipmentOrderByName,
} from "../../../api/Equipment";
import { infoIDs, readInfoById } from "../../../api/Info";
import { LocationContext, locationIDs } from "../../../context/LocationContext";
import EquipmentModel from "../../../models/Equipment";
import ServiceInfoModel from "../../../models/infoModels/ServiceInfoModel";
import { devices } from "../../../theme/devices";
import { animationKeyFrames } from "../../../theme/keyframes";
import {
  ButtonList,
  Centered,
  colorTheme,
  H1,
  H2,
  H4,
  MapContainer,
  P,
  Section,
} from "../../../theme/Styles";
import { VideoContainer } from "../../portal/Styles";
import ProcessImage from "../../processImage/ProcessImage";
import PrimaryButton from "../../ui/buttons/PrimaryButton";
import FormattedAddress from "../../ui/formattedAddress/FormattedAddress";
import FormattedList from "../../ui/formattedList/FormattedList";
import FormattedP from "../../ui/formattedP/FormattedP";
import {
  HeroContainer,
  HeroDescription,
  HeroImage,
  HeroImageContainer,
  HeroOverviewContainer,
  HeroSection,
  HeroText,
} from "../../ui/heroComponents/HeroStyledComponents";
import LoadingPage from "../../ui/loadingPage/LoadingPage";
import SingleItem from "../equipment/Item";
import heroImage from "./assets/borrow_hero.jpg";
import FAQ from "./FAQ";

const BorrowPageContainer = styled.div`
  scroll-behavior: smooth;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  transition: height 0.5s;
  animation: ${animationKeyFrames.fadeIn} 0.5s;
  justify-content: space-between;
  margin: 3vw auto;
  margin-bottom: 0;

  @media ${devices.mobile} {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  overflow: hidden;
  margin: 2vw 0vw;
  background-color: none;
  animation: ${animationKeyFrames.fadeIn} 0.5s;
`;

const ItemImageContainer = styled.div`
  width: 100%;
  height: 18vw;
  background-color: ${colorTheme.darkGrey};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemImage = styled.img`
  width: 100%;
  height: auto;
`;

const AddressContainer = styled.div`
  display: flex;
  margin: 0.5vw 0;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const AddressText = styled.div``;

const PairContainer = styled.div`
  margin: 0.3vw 0;
  @media ${devices.mobile} {
    margin: 5vw 0;
  }
`;

const Segment = styled.div`
  margin-right: 10vw;
  @media ${devices.mobile} {
    margin: 5vw 0;
  }
`;

const Pair = styled.div`
  display: flex;
  /* height: 3vw; */
  margin-bottom: 0.5vw;

  p {
    min-width: 8vw;
    margin: 0.4vw 0;
  }
  @media ${devices.mobile} {
    margin: 5vw 0;
    height: auto;
    p {
      width: 40vw;
      margin: 0;
    }
  }
`;

const ProcessTextContainer = styled.div`
  width: 41vw;
  margin: auto;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

export default function Borrow(props: RouteComponentProps<any>) {
  const [equipmentList, setEquipmentList] = useState<EquipmentModel[]>([]);
  const [serviceInfo, setServiceInfo] = useState<ServiceInfoModel | null>(null);

  const { location, setLocation } = useContext(LocationContext);

  useEffect(() => {
    setLocation(locationIDs.borrow);
  }, []);

  useEffect(() => {
    readAllEquipmentOrderByName((response) => {
      if (response) setEquipmentList(response);
    });
  }, []);

  useEffect(() => {
    readInfoById(infoIDs.serviceInfo, (response) => {
      if (response) setServiceInfo(response);
    });
  }, []);

  if (!serviceInfo) return <LoadingPage></LoadingPage>;
  return (
    <BorrowPageContainer>
      <HeroSection backgroundColor={colorTheme.primary}>
        <HeroContainer reverseOnMobile>
          <HeroText>
            {/* <Logo src={logoImage}></Logo> */}
            <H1 color={colorTheme.primaryLight}>Borrowing equipment </H1>
            {serviceInfo.overview && (
              <HeroOverviewContainer>
                <FormattedP
                  color={colorTheme.white}
                  text={serviceInfo.overview}
                ></FormattedP>
              </HeroOverviewContainer>
            )}
            {/* <HeroDescription color={colorTheme.white}>
              {" "}
              We have a wide range of durable medical equipment to help you when
              you need it the most; from wheelchairs to shower chairs to walking
              canes. We lend out equipment for 90 days.
            </HeroDescription> */}
            {/* <ButtonList>
              <Link href={"#equipmentlist"}>equipment list</Link>
              <Link href={"#visitus"}>our location</Link>
              <Link href={"#visitus"}>Hours of operation</Link>
              <Link href={"#faq"}>FAQ</Link>
            </ButtonList> */}
          </HeroText>
          <HeroImageContainer>
            <HeroImage src={heroImage}></HeroImage>
          </HeroImageContainer>
        </HeroContainer>
      </HeroSection>

      <Section id={"process"}>
        <Centered>
          <H2 center color={colorTheme.secondary}>
            Our borrowing process is easy
          </H2>
          {serviceInfo.processText && (
            <ProcessTextContainer>
              <FormattedP center text={serviceInfo.processText}></FormattedP>
            </ProcessTextContainer>
          )}

          <ProcessImage></ProcessImage>
        </Centered>
      </Section>

      <Section id={"equipmentlist"} backgroundColor={colorTheme.extraLightGrey}>
        <Centered>
          <H2 center color={colorTheme.primary}>
            Equipment list
          </H2>
          <ItemContainer>
            {equipmentList
              .filter((i) => i.isPublished)
              .map((item, key) => {
                if (key > 5) return null;
                return (
                  <SingleItem
                    data={item}
                    onHowToClick={() => {
                      window.scrollTo(0, 300);
                      props.history.push("borrow");
                    }}
                  ></SingleItem>
                );
              })}
          </ItemContainer>
          <ButtonList smallTopMargin center>
            <PrimaryButton
              text={"View complete equipment list"}
              onClick={() => {
                window.scrollTo(0, 0);
                props.history.push("../equipment");
              }}
            ></PrimaryButton>
          </ButtonList>
        </Centered>
      </Section>

      <Section id={"visitus"}>
        <H2 leftOnMobile color={colorTheme.primary}>
          Visit us
        </H2>
        <H4>Our Address</H4>

        {serviceInfo.address && (
          <AddressContainer>
            <FormattedAddress text={serviceInfo.address}></FormattedAddress>
          </AddressContainer>
        )}
        {serviceInfo.googleMapLink && (
          <MapContainer>
            <iframe
              src={serviceInfo.googleMapLink}
              width="600"
              height="450"
              frameBorder="0"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </MapContainer>
        )}

        <AddressContainer>
          {serviceInfo.holidays && (
            <Segment>
              <H4>Days we are closed</H4>
              <PairContainer>
                {serviceInfo.holidays.january && (
                  <Pair>
                    <P strong>January</P>
                    <FormattedList
                      text={serviceInfo.holidays.january}
                    ></FormattedList>
                  </Pair>
                )}
                {serviceInfo.holidays.february && (
                  <Pair>
                    <P strong>February</P>
                    <FormattedList
                      text={serviceInfo.holidays.february}
                    ></FormattedList>
                  </Pair>
                )}

                {serviceInfo.holidays.march && (
                  <Pair>
                    <P strong>March</P>
                    <FormattedList
                      text={serviceInfo.holidays.march}
                    ></FormattedList>
                  </Pair>
                )}

                {serviceInfo.holidays.april && (
                  <Pair>
                    <P strong>April</P>
                    <FormattedList
                      text={serviceInfo.holidays.april}
                    ></FormattedList>
                  </Pair>
                )}

                {serviceInfo.holidays.may && (
                  <Pair>
                    <P strong>May</P>
                    <FormattedList
                      text={serviceInfo.holidays.may}
                    ></FormattedList>
                  </Pair>
                )}
                {serviceInfo.holidays.june && (
                  <Pair>
                    <P strong>June</P>
                    <FormattedList
                      text={serviceInfo.holidays.june}
                    ></FormattedList>
                  </Pair>
                )}

                {serviceInfo.holidays.july && (
                  <Pair>
                    <P strong>July</P>
                    <FormattedList
                      text={serviceInfo.holidays.july}
                    ></FormattedList>
                  </Pair>
                )}
                {serviceInfo.holidays.august && (
                  <Pair>
                    <P strong>August</P>
                    <FormattedList
                      text={serviceInfo.holidays.august}
                    ></FormattedList>
                  </Pair>
                )}
                {serviceInfo.holidays.september && (
                  <Pair>
                    <P strong>September</P>
                    <FormattedList
                      text={serviceInfo.holidays.september}
                    ></FormattedList>
                  </Pair>
                )}

                {serviceInfo.holidays.october && (
                  <Pair>
                    <P strong>October</P>
                    <FormattedList
                      text={serviceInfo.holidays.october}
                    ></FormattedList>
                  </Pair>
                )}

                {serviceInfo.holidays.november && (
                  <Pair>
                    <P strong>November</P>
                    <FormattedList
                      text={serviceInfo.holidays.november}
                    ></FormattedList>
                  </Pair>
                )}

                {serviceInfo.holidays.december && (
                  <Pair>
                    <P strong>December</P>
                    <FormattedList
                      text={serviceInfo.holidays.december}
                    ></FormattedList>
                  </Pair>
                )}
              </PairContainer>
            </Segment>
          )}
          {serviceInfo.hours && (
            <Segment>
              <H4>Hours of Operation</H4>
              <PairContainer>
                <FormattedList text={serviceInfo.hours}></FormattedList>
              </PairContainer>
            </Segment>
          )}
        </AddressContainer>
      </Section>
      {serviceInfo.FAQs && (
        <Section id={"faq"} backgroundColor={colorTheme.extraLightGrey}>
          <H2 color={colorTheme.secondary} leftOnMobile>
            Frequently asked questions
          </H2>

          {serviceInfo.FAQs.map((faq, index) => {
            return <FAQ data={faq}></FAQ>;
          })}
        </Section>
      )}
    </BorrowPageContainer>
  );
}

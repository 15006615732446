import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { readEventById } from "../../../api/Events";
import { blockTypes } from "../../../constants/BlockTypes";
import { LocationContext, locationIDs } from "../../../context/LocationContext";
import Event from "../../../models/Event";
import { devices } from "../../../theme/devices";
import { colorTheme, H1, P } from "../../../theme/Styles";
import LoadingWidget from "../../portal/ui/loadingWidget/LoadingWidget";
import { HeroSection } from "../../ui/heroComponents/HeroStyledComponents";
import LoadingPage from "../../ui/loadingPage/LoadingPage";
import ImageBlockView from "./ImageBlock";
import InfoBlockView from "./InfoBlock";

const HeroTextContainer = styled.div`
  width: 100%;
  /* background-color: ${colorTheme.primary}; */
  margin: auto;
  padding: 3vw 15vw;
  position: relative;

  @media ${devices.mobile} {
    padding: 5vw 10vw;
  }
`;

const DateLocationContainer = styled.div`
  width: 100%;
  /* background-color: ${colorTheme.primary}; */
  margin: auto;
  position: relative;

  @media ${devices.mobile} {
    margin-top: 10vw;
  }
`;

const DateLocationText = styled(P)`
  font-size: 1.4vw;
  margin: 0.7vw 0;

  @media ${devices.mobile} {
    font-size: 5vw;
    margin: 2vw 0;
  }
`;

const PlaceHolder = styled.div`
  width: 100%;
  height: 60vw;
`;

export const HeroImageContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 30vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
  @media ${devices.mobile} {
    width: 100%;
    max-height: none;
  }
`;

export default function SingleEvent(
  props: RouteComponentProps<{ id: string }>
) {
  const [eventData, setEventData] = useState<Event | null>(null);

  const { setLocation } = useContext(LocationContext);

  useEffect(() => {
    setLocation(locationIDs.singleEvent);
  }, []);

  useEffect(() => {
    readEventById(props.match.params.id, (response) => {
      if (response) {
        setEventData(response);
      }
    });
  }, []);

  // if (!eventData) return <PlaceHolder></PlaceHolder>;
  if (!eventData) return <LoadingPage></LoadingPage>;

  return (
    <HeroSection>
      {eventData.heroImage && (
        <HeroImageContainer>
          <img src={eventData.heroImage}></img>
        </HeroImageContainer>
      )}
      <HeroTextContainer>
        {eventData.title && <H1>{eventData.title}</H1>}
        {eventData.description && <P width={30}>{eventData.description}</P>}
        <DateLocationContainer>
          {eventData.date && (
            <DateLocationText strong width={30}>
              {eventData.date}
            </DateLocationText>
          )}
          {eventData.location && (
            <DateLocationText strong width={30}>
              {eventData.location}
            </DateLocationText>
          )}
        </DateLocationContainer>
      </HeroTextContainer>
      {eventData.blocks &&
        eventData.blocks.map((block) => {
          if (block.type === blockTypes.INFO)
            return <InfoBlockView block={block}></InfoBlockView>;
          if (block.type === blockTypes.IMAGE)
            return <ImageBlockView block={block}></ImageBlockView>;
        })}
    </HeroSection>
  );
}

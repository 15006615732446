import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { uploadFile } from "../../../../FirebaseStorage/FirebaseStorage";
import PrimaryButton from "../../../ui/buttons/PrimaryButton";
import { colorTheme, H3 } from "../../Styles";
import Button from "../Button";

const BodyContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
const FileInputContainer = styled(Container)`
  height: 20vh;
`;

const FileBrowser = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const FileBrowserLable = styled.label`
  margin: auto;
  padding: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s;
  cursor: pointer;
  box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid ${colorTheme.black};
  border-radius: 0.5vw;

  &:hover {
    transform: scale(1.05);
  }
`;

const LabelText = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.025em;
  margin: 0 1vw;
`;

const ImageContainer = styled(Container)`
  height: 40vh;
`;

const Image = styled.img`
  max-width: 50%;
  width: auto;
  margin: auto;
`;

const ButtonContainer = styled(Container)`
  height: 10vh;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5vw;
`;

const Icon = styled.img`
  height: 4vh;
  width: auto;
  padding: 0 2vh;
`;

interface IAppProps {
  onUpload: (url: string | null, fileName?: string | null) => void;
  onCloseTrigger: () => void;
  show: boolean;
}
interface IAppState {
  selectedFileData: any | null;
  selectedFile: any | null;
  fileName: string | null;
  loading: boolean;
}

export default class FileInput extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      selectedFile: null,
      selectedFileData: null,
      fileName: null,
      loading: false,
    };
  }

  handleChange(selectedFiles: FileList | null) {
    if (selectedFiles) {
      if (FileReader) {
        var fr = new FileReader();
        fr.onload = () => {
          this.setState({ selectedFileData: fr.result });
        };
        fr.readAsDataURL(selectedFiles[0]);
      }
      this.setState({ selectedFile: selectedFiles[0] });
    }
  }

  onUploadClick() {
    this.setState({ loading: true });
    const fileName = this.state.selectedFile.name.split(".")[0];
    const extension = this.state.selectedFile.name.split(".")[1];
    //todo:do a proper validation
    if (!fileName || !extension) {
      alert("Invalid file name or type");
      return;
    }
    uploadFile(this.state.selectedFile.name, this.state.selectedFile, (url) => {
      this.props.onUpload(url, this.state.selectedFile.name);
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onCloseTrigger}>
        <Modal.Header closeButton>
          <H3>Browse File</H3>
        </Modal.Header>
        <Modal.Body>
          <BodyContainer>
            <ImageContainer>
              <Image src={this.state.selectedFileData}></Image>
            </ImageContainer>
            <FileInputContainer>
              <FileBrowser
                type="file"
                name="file"
                id="file"
                onChange={(ev) => this.handleChange(ev.target.files)}
              ></FileBrowser>
              <FileBrowserLable htmlFor="file">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 36 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.75098 35.3022V40.56H17.8999H34.0488V35.8864"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.0745 1.2074C17.4716 0.626596 18.3284 0.626595 18.7255 1.2074L31.4662 19.8423C31.92 20.506 31.4447 21.4067 30.6407 21.4067H5.15926C4.35525 21.4067 3.87998 20.506 4.33376 19.8423L17.0745 1.2074Z"
                    fill="black"
                  />
                  <rect
                    x="12"
                    y="16"
                    width="12.7689"
                    height="15.7733"
                    rx="1"
                    fill="black"
                  />
                </svg>
                <LabelText>
                  {this.state.selectedFile
                    ? this.state.selectedFile.name
                    : "Choose a file..."}
                </LabelText>
              </FileBrowserLable>
            </FileInputContainer>
            <ButtonContainer>
              <Button
                text="Upload"
                onClick={() => this.onUploadClick()}
                disable={!this.state.selectedFile}
                loading={this.state.loading}
              ></Button>
            </ButtonContainer>
          </BodyContainer>
        </Modal.Body>
      </Modal>
      // <DialogBoxFrame
      //   onCloseTriggered={() => this.props.onCloseTrigger()}
      //   body={
      //     <BodyContainer>
      //       <ImageContainer>
      //         <Image src={this.state.selectedFileData}></Image>
      //       </ImageContainer>
      //       <FileInputContainer>
      //         <FileBrowser
      //           type="file"
      //           name="file"
      //           id="file"
      //           onChange={(ev) => this.handleChange(ev.target.files)}
      //         ></FileBrowser>
      //         <FileBrowserLable htmlFor="file">
      //           <svg
      //             width="25"
      //             height="25"
      //             viewBox="0 0 36 42"
      //             fill="none"
      //             xmlns="http://www.w3.org/2000/svg"
      //           >
      //             <path
      //               d="M1.75098 35.3022V40.56H17.8999H34.0488V35.8864"
      //               stroke="black"
      //               stroke-width="2"
      //               stroke-linecap="round"
      //               stroke-linejoin="round"
      //             />
      //             <path
      //               d="M17.0745 1.2074C17.4716 0.626596 18.3284 0.626595 18.7255 1.2074L31.4662 19.8423C31.92 20.506 31.4447 21.4067 30.6407 21.4067H5.15926C4.35525 21.4067 3.87998 20.506 4.33376 19.8423L17.0745 1.2074Z"
      //               fill="black"
      //             />
      //             <rect
      //               x="12"
      //               y="16"
      //               width="12.7689"
      //               height="15.7733"
      //               rx="1"
      //               fill="black"
      //             />
      //           </svg>
      //           <LabelText>
      //             {this.state.selectedFile
      //               ? this.state.selectedFile.name
      //               : "Choose a file..."}
      //           </LabelText>
      //         </FileBrowserLable>
      //       </FileInputContainer>
      //       <ButtonContainer>
      //         <PrimaryButton
      //           text="Upload"
      //           onClick={() => this.onUploadClick()}
      //           // disable={!this.state.selectedFile}
      //         ></PrimaryButton>
      //       </ButtonContainer>
      //     </BodyContainer>
      //   }
      // ></DialogBoxFrame>
    );
  }
}

import React from "react";
import styled from "styled-components";
import { colorTheme, P, TextArea } from "../../../../theme/Styles";

const Container = styled.div`
  box-sizing: border-box;
`;

const TextInput = styled(TextArea)`
  width: 100%;
  height: auto;
  max-width: 100%;
  font-style: normal;
  font-size: 1.2vw;
  margin: 0.3vw 0;
  padding: 0.8vw;
  resize: none;
  outline: none;
  border: none;
  border-radius: 0.5vw;
  background-color: ${colorTheme.lightGrey};
`;

const Label = styled(P)`
  font-weight: 700;
  font-size: 1vw;
  margin: 0;
  color: ${colorTheme.black};
`;

export default function TextField(props: {
  label: string;
  text?: string;
  onChange: (text: string) => void;
}) {
  return (
    <Container>
      <Label> {props.label}</Label>
      <TextInput
        rows={1}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.text || ""}
      ></TextInput>
    </Container>
  );
}

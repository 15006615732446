import React from "react";
import styled from "styled-components";

import { blockTypes } from "../../../../../constants/BlockTypes";
import InfoBlockModel from "../../../../../models/blockModels/ImageBlock";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import Button from "../../Button";

const Container = styled.div`
  width: 70vw;
  display: flex;
`;

export default function BlockToolBar(props: {
  blockTypes?: number[];
  onAdd: (block: InfoBlockModel) => void;
}) {
  const shouldInclude = (blockType: number) => {
    if (!props.blockTypes) return true;
    if (props.blockTypes.includes(blockType)) return true;
    return false;
  };

  return (
    <Container>
      {shouldInclude(blockTypes.INFO) && (
        <Button
          text={"Add info block"}
          onClick={() => {
            props.onAdd({ type: blockTypes.INFO });
          }}
        ></Button>
      )}

      {shouldInclude(blockTypes.IMAGE) && (
        <Button
          text={"Add image block"}
          onClick={() => {
            props.onAdd({ type: blockTypes.IMAGE });
          }}
        ></Button>
      )}

      {shouldInclude(blockTypes.FAQ) && (
        <Button
          text={"Add FAQ block"}
          onClick={() => {
            props.onAdd({ type: blockTypes.FAQ });
          }}
        ></Button>
      )}
    </Container>
  );
}

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { infoIDs, readInfoById, updateInfoById } from "../../../../api/Info";
import { AlertContext } from "../../../../context/AlertContext";
import DonationsInfoModel from "../../../../models/infoModels/DonationsInfoModel";
import VolunteerInfoModel from "../../../../models/infoModels/VolunteerInfoModel";
import LinkModel from "../../../../models/LinkModel";
import VolunteerRequestModel from "../../../../models/VolunteerRequestModal";
import {
  Body,
  H2,
  H3,
  HorizontalFlex,
  Link,
  Page,
  VerticalCushion,
  StickyHeader,
} from "../../Styles";
import Button from "../../ui/Button";
import BlockList from "../../ui/editables/editableBlocks/BlockList";
import EditableDownloadLink from "../../ui/editables/editableDownloadLink/EditableDownloadLink";
import EditableText from "../../ui/editables/editableText/EditableText";
import EditableWebLink from "../../ui/editables/editableWebLink/EditableWebLink";

const AddLinksContainer = styled.div`
  /* display: flex;
  flex-direction: flex-start; */
`;

export default function Volunteers() {
  const [volunteerInfo, setvolunteerInfo] = useState<VolunteerInfoModel>({});
  const { alert, setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const update = () => {
    setLoading(true);
    updateInfoById(infoIDs.volunteerInfo, volunteerInfo, (response) => {
      readVolunteerInfo();
      setAlert({ visible: true, message: response.message });
      setLoading(false);
    });
  };

  useEffect(() => {
    readVolunteerInfo();
  }, []);

  const readVolunteerInfo = () => {
    readInfoById(infoIDs.volunteerInfo, (response) => {
      if (response) setvolunteerInfo(response);
    });
  };

  const onCommiteeLinkChange = (link: LinkModel, index: number) => {
    var info = { ...volunteerInfo };
    if (info.commiteeMemberslinks) {
      info.commiteeMemberslinks.splice(index, 1, link);
    } else {
      info.commiteeMemberslinks = [link];
    }
    setvolunteerInfo(info);
  };

  const onCommiteeLinkRemove = (index: number) => {
    var info = { ...volunteerInfo };
    if (info.commiteeMemberslinks) {
      info.commiteeMemberslinks.splice(index, 1);
    }
    setvolunteerInfo(info);
  };

  const onDirectorLinkChange = (link: LinkModel, index: number) => {
    var info = { ...volunteerInfo };
    if (info.directorsLinks) {
      info.directorsLinks.splice(index, 1, link);
    } else {
      info.directorsLinks = [link];
    }
    setvolunteerInfo(info);
  };

  const onDirectorLinkRemove = (index: number) => {
    var info = { ...volunteerInfo };
    if (info.directorsLinks) {
      info.directorsLinks.splice(index, 1);
    }
    setvolunteerInfo(info);
  };

  return (
    <Page>
      <Body>
        <VerticalCushion small> </VerticalCushion>
        <Link href={"/portal"}>Dashboard</Link>{" "}
        <StickyHeader>
          <H2>Volunteer Page</H2>
          <Button
            loading={loading}
            primary
            text={"Update"}
            onClick={update}
          ></Button>
        </StickyHeader>
        <VerticalCushion small> </VerticalCushion>
        <EditableText
          label={"Page Overview"}
          width={50}
          rows={6}
          text={volunteerInfo.overview || ""}
          placeholder={""}
          onChange={(text) =>
            setvolunteerInfo({
              ...volunteerInfo,
              overview: text,
            })
          }
        ></EditableText>
        <VerticalCushion small> </VerticalCushion>
        <H3>Front Desk Volunteers</H3>
        <EditableText
          width={60}
          rows={10}
          text={volunteerInfo.frontDeskVolunteersText || ""}
          placeholder={""}
          onChange={(text) =>
            setvolunteerInfo({
              ...volunteerInfo,
              frontDeskVolunteersText: text,
            })
          }
        ></EditableText>
        <VerticalCushion>
          <H3>Commitee Members</H3>
          <EditableText
            width={60}
            rows={10}
            text={volunteerInfo.commiteeMembersText || ""}
            placeholder={""}
            onChange={(text) =>
              setvolunteerInfo({
                ...volunteerInfo,
                commiteeMembersText: text,
              })
            }
          ></EditableText>
          {volunteerInfo.commiteeMemberslinks &&
            volunteerInfo.commiteeMemberslinks.map((link, index) => {
              if (link.fileName) {
                return (
                  <EditableDownloadLink
                    downloadLink={link}
                    onRemove={() => onCommiteeLinkRemove(index)}
                    onChange={(link) => onCommiteeLinkChange(link, index)}
                  ></EditableDownloadLink>
                );
              } else
                return (
                  <EditableWebLink
                    webLink={link}
                    onRemove={() => onCommiteeLinkRemove(index)}
                    onChange={(link) => onCommiteeLinkChange(link, index)}
                  ></EditableWebLink>
                );
            })}

          <AddLinksContainer>
            <EditableWebLink
              webLink={{}}
              onRemove={() =>
                onCommiteeLinkRemove(
                  volunteerInfo.commiteeMemberslinks
                    ? volunteerInfo.commiteeMemberslinks.length
                    : 0
                )
              }
              onChange={(link) =>
                onCommiteeLinkChange(
                  link,
                  volunteerInfo.commiteeMemberslinks
                    ? volunteerInfo.commiteeMemberslinks.length
                    : 0
                )
              }
            ></EditableWebLink>

            <EditableDownloadLink
              downloadLink={{}}
              onRemove={() =>
                onCommiteeLinkRemove(
                  volunteerInfo.commiteeMemberslinks
                    ? volunteerInfo.commiteeMemberslinks.length
                    : 0
                )
              }
              onChange={(link) =>
                onCommiteeLinkChange(
                  link,
                  volunteerInfo.commiteeMemberslinks
                    ? volunteerInfo.commiteeMemberslinks.length
                    : 0
                )
              }
            ></EditableDownloadLink>
          </AddLinksContainer>
        </VerticalCushion>
        <VerticalCushion>
          <H3>Board of Directors</H3>
          <EditableText
            width={60}
            rows={10}
            text={volunteerInfo.directorsText || ""}
            placeholder={""}
            onChange={(text) =>
              setvolunteerInfo({
                ...volunteerInfo,
                directorsText: text,
              })
            }
          ></EditableText>

          {volunteerInfo.directorsLinks &&
            volunteerInfo.directorsLinks.map((link, index) => {
              if (link.fileName) {
                return (
                  <EditableDownloadLink
                    downloadLink={link}
                    onRemove={() => onDirectorLinkRemove(index)}
                    onChange={(link) => onDirectorLinkChange(link, index)}
                  ></EditableDownloadLink>
                );
              } else
                return (
                  <EditableWebLink
                    webLink={link}
                    onRemove={() => onDirectorLinkRemove(index)}
                    onChange={(link) => onDirectorLinkChange(link, index)}
                  ></EditableWebLink>
                );
            })}

          <AddLinksContainer>
            <EditableWebLink
              webLink={{}}
              onRemove={() =>
                onDirectorLinkRemove(
                  volunteerInfo.directorsLinks
                    ? volunteerInfo.directorsLinks.length
                    : 0
                )
              }
              onChange={(link) =>
                onDirectorLinkChange(
                  link,
                  volunteerInfo.directorsLinks
                    ? volunteerInfo.directorsLinks.length
                    : 0
                )
              }
            ></EditableWebLink>

            <EditableDownloadLink
              downloadLink={{}}
              onRemove={() =>
                onDirectorLinkRemove(
                  volunteerInfo.directorsLinks
                    ? volunteerInfo.directorsLinks.length
                    : 0
                )
              }
              onChange={(link) =>
                onDirectorLinkChange(
                  link,
                  volunteerInfo.directorsLinks
                    ? volunteerInfo.directorsLinks.length
                    : 0
                )
              }
            ></EditableDownloadLink>
          </AddLinksContainer>
        </VerticalCushion>
      </Body>
    </Page>
  );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import LinkModel from "../../../../../models/LinkModel";
import { colorTheme } from "../../../../../theme/Styles";
import Button from "../../Button";
import IconWithText from "../../IconWithText/IconWithText";
import RemoveButton from "../../removeButton/RemoveButton";
import TextField from "../../textfield/TextField";
import linkIcon from "./assets/link_icon.svg";

const Container = styled.div`
  position: relative;
  /* min-width: 12vw; */
`;

const IconContainer = styled.div`
  display: inline-flex;
`;

const LinkInputContainer = styled.div<{ show: boolean }>`
  /* position: absolute; */
  width: 30vw;
  height: ${(props) => (props.show ? 20 : 0)}vw;
  padding: ${(props) => (props.show ? 2 : 0)}vw;
  margin-left: 3vw;
  margin-top: 1vw;

  border: ${(props) => (props.show ? 1 : 0)}px solid ${colorTheme.darkGrey};
  /* background-color: ${colorTheme.extraLightGrey}; */
  border-radius: 1vw;
  transition: height 0.2s;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1vw 0;
`;

export default function EditableWebLink(props: {
  webLink: LinkModel;
  onRemove: () => void;
  onChange: (webLink: LinkModel) => void;
}) {
  const [showDialog, setShowDialog] = useState(false);

  const [linkText, setlinkText] = useState("");
  const [url, setUrl] = useState("");

  const [showRemove, setShowRemove] = useState(false);

  useEffect(() => {
    if (!showDialog) {
      setlinkText(props.webLink.linkText || "");
      setUrl(props.webLink.url || "");
    }
    // setShowDialog(false);
  }, [props]);

  const resetAndCloseDialog = () => {
    setlinkText(props.webLink.linkText || "");
    setUrl(props.webLink.url || "");
    setShowDialog(false);
  };
  return (
    <Container>
      <IconContainer
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
      >
        <IconWithText
          small={!linkText}
          iconSrc={linkIcon}
          text={props.webLink.linkText || "Add web link"}
          onClick={() => {
            !showDialog && setShowDialog(true);
            showDialog && resetAndCloseDialog();
          }}
        ></IconWithText>
        {showRemove && props.webLink.linkText && !showDialog && (
          <RemoveButton
            onRemoveTriggered={() => props.onRemove()}
          ></RemoveButton>
        )}
      </IconContainer>
      <LinkInputContainer show={showDialog}>
        {showDialog && (
          <div>
            <TextField
              label={"Link Text"}
              text={linkText}
              onChange={(text) => setlinkText(text)}
            ></TextField>
            <TextField
              label={"Web URL"}
              text={url}
              onChange={(text) => setUrl(text)}
            ></TextField>
            <ButtonContainer>
              <Button
                small
                text={"Cancel"}
                onClick={() => resetAndCloseDialog()}
              ></Button>
              <Button
                small
                disable={!linkText || !url}
                text={"Save"}
                onClick={() => {
                  setShowDialog(false);
                  props.onChange({ linkText: linkText, url: url });
                }}
              ></Button>
            </ButtonContainer>
          </div>
        )}
      </LinkInputContainer>
    </Container>
  );
}

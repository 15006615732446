import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useInterval } from "../../../hooks/UseInterval";
import { devices } from "../../../theme/devices";
import { animationKeyFrames } from "../../../theme/keyframes";

import { colorTheme } from "../../../theme/Styles";

import logoImage from "./assets/logo_white.svg";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${colorTheme.primaryVariant};
  flex-direction: column;
  z-index: 2000;
  /* animation: ${animationKeyFrames.fadeIn} 1s; */
`;
const DotWrapper = styled.div`
  width: 6%;
  /* margin: 50vh auto; */
  display: flex;
  justify-content: space-around;
  @media ${devices.mobile} {
    width: 15vw;
    height: 3vw;
  }
`;

const Dot = styled.div<{ selected: boolean }>`
  width: ${(props) => (props.selected ? 0.7 : 0.6)}vw;
  height: ${(props) => (props.selected ? 0.7 : 0.6)}vw;
  background-color: ${(props) =>
    props.selected ? colorTheme.white : colorTheme.primaryLight};
  border-radius: 5vw;
  @media ${devices.mobile} {
    width: ${(props) => (props.selected ? 2.2 : 2)}vw;
    height: ${(props) => (props.selected ? 2.2 : 2)}vw;
  }
`;

const Logo = styled.img`
  width: 10vw;
  height: auto;
  /* animation: ${animationKeyFrames.appear} 5s; */

  @media ${devices.mobile} {
    width: 25vw;
  }
`;

export default function LoadingPage() {
  var intervelRef;
  const DOT_COUNT = 3;

  let [count, setCount] = useState(0);

  useInterval(() => {
    // Your custom logic heres
    setCount(count + 1);
  }, 200);

  const renderLoadingWidgetDots = () => {
    let dots = [];
    let selected = false;
    let loadingIndex = count % DOT_COUNT;
    for (let i = 0; i < DOT_COUNT; i++) {
      selected = i == loadingIndex;
      dots.push(<Dot selected={selected}></Dot>);
    }
    return dots;
    //return <p>{count}</p>;
  };

  return (
    <Container>
      <Logo src={logoImage}></Logo>
      <DotWrapper>{renderLoadingWidgetDots()}</DotWrapper>
    </Container>
  );
}

import React from "react";
import styled from "styled-components";
import ImageBlockModel from "../../../models/blockModels/ImageBlock";
import InfoBlockModel from "../../../models/blockModels/InfoBlock";
import { devices } from "../../../theme/devices";
import { ButtonList, colorTheme, Link, P, H3 } from "../../../theme/Styles";
import DownloadLink from "../../ui/buttons/DownloadLink";
import FormattedP from "../../ui/formattedP/FormattedP";

const Container = styled.div`
  width: 80%;
  padding: 2vw 0;
  padding-bottom: 0;
  margin: 5vw auto;

  @media ${devices.mobile} {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0vw;
    margin: 15vw 0;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  @media ${devices.mobile} {
    width: 100%;
    height: auto;
  }
`;
const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Text = styled.div`
  width: 80%;
  margin: 2vw;

  @media ${devices.mobile} {
    width: 80%;
    margin: auto;
  }
`;

export default function ImageBlockView(props: { block: ImageBlockModel }) {
  return (
    <Container>
      <Text>
        <H3>{props.block.header}</H3>
        {props.block.description && (
          <FormattedP width={40} text={props.block.description}></FormattedP>
        )}
      </Text>
      {props.block.url && (
        <ImageContainer>
          <Image src={props.block.url}></Image>
        </ImageContainer>
      )}
    </Container>
  );
}

import styled from "styled-components";
import FAQBlockModel from "../../../../../models/blockModels/FAQBlock";

import { colorTheme } from "../../../../../theme/Styles";
import EditableText from "../editableText/EditableText";

const Container = styled.div<{ isDragging: boolean }>`
  width: 100%;
  height: auto;
  transform: scale(${(props) => (props.isDragging ? 0.8 : 1)});
  transition: transform 0.5s;
  /* background-color: ${colorTheme.lightGrey}; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function FAQBlock(props: {
  block: FAQBlockModel;
  onBlockChange: (block: FAQBlockModel) => void;
  isDragging: boolean;
}) {
  return (
    <Container isDragging={props.isDragging}>
      <EditableText
        size={1.6}
        weight={600}
        width={80}
        rows={2}
        text={props.block.question || ""}
        placeholder={"Header"}
        onChange={(text) => {
          props.onBlockChange({ ...props.block, question: text });
        }}
      ></EditableText>
      {!props.isDragging && (
        <EditableText
          width={80}
          rows={4}
          text={props.block.answer || ""}
          placeholder={"Description"}
          onChange={(text) => {
            props.onBlockChange({ ...props.block, answer: text });
          }}
        ></EditableText>
      )}{" "}
    </Container>
  );
}

import React from "react";
import styled from "styled-components";
import { colorTheme } from "../../../theme/Styles";

const Container = styled.div<{ backgroundColor?: string }>`
  margin: auto;
  border-radius: 2vw;
  background-color: ${(props) => props.backgroundColor || colorTheme.lightGrey};
  /* display: inline-flex; */
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 2vw;
  height: 2vw;
  transition: transform 0.5s;
  cursor: pointer;
  margin: auto;
  padding: 0.4vw;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    opacity: 0.5;
  }
`;

interface iconProps {
  src: string;
  backgroundColor?: string;
  onClick: () => void;
}

export default function Icon(props: iconProps) {
  return (
    <Container
      backgroundColor={props.backgroundColor}
      onClick={() => props.onClick()}
    >
      <Image src={props.src}></Image>
    </Container>
  );
}

import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";

import processImage from "./assets/process.svg";
import processMobileImage from "./assets/process_mobile.svg";

const Image = styled.img`
  width: 100%;
  margin: 2vw auto;
  margin-bottom: 0.2vw;
  height: auto;
  @media ${devices.mobile} {
    display: none;
  }
`;

const MobileImage = styled.img`
  width: 100%;
  margin: 5vw auto;
  margin-bottom: 0.2vw;
  height: auto;
  display: none;
  @media ${devices.mobile} {
    display: block;
  }
`;

export default function ProcessImage() {
  return (
    <React.Fragment>
      <Image src={processImage}></Image>
      <MobileImage src={processMobileImage}></MobileImage>
    </React.Fragment>
  );
}

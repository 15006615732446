import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { infoIDs, readInfoById, updateInfoById } from "../../../../api/Info";
import { AlertContext } from "../../../../context/AlertContext";
import AboutUsInfoModel from "../../../../models/infoModels/AboutUsInfoModel";
import DonationsInfoModel from "../../../../models/infoModels/DonationsInfoModel";
import HomePageInfoModel from "../../../../models/infoModels/HomePageInfoModel";
import {
  Body,
  H2,
  H3,
  HorizontalFlex,
  Link,
  Page,
  VerticalCushion,
  StickyHeader,
} from "../../Styles";
import Button from "../../ui/Button";
import BlockList from "../../ui/editables/editableBlocks/BlockList";
import EditableText from "../../ui/editables/editableText/EditableText";

export default function Home() {
  const [homePageInfo, setHomePageInfo] = useState<HomePageInfoModel>({});
  const { alert, setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const update = () => {
    setLoading(true);
    updateInfoById(infoIDs.homeInfo, homePageInfo, (response) => {
      readHomePageInfo();
      setAlert({ visible: true, message: response.message });
      setLoading(false);
    });
  };

  useEffect(() => {
    readHomePageInfo();
  }, []);

  const readHomePageInfo = () => {
    readInfoById(infoIDs.homeInfo, (response) => {
      if (response) setHomePageInfo(response);
    });
  };

  return (
    <Page>
      <Body>
        <VerticalCushion small> </VerticalCushion>
        <Link href={"/portal"}>Dashboard</Link>{" "}
        <StickyHeader>
          <H2>Home Page</H2>
          <Button
            loading={loading}
            primary
            text={"Update"}
            onClick={update}
          ></Button>
        </StickyHeader>
        <VerticalCushion small> </VerticalCushion>
        <EditableText
          label={"Tagline"}
          width={50}
          rows={2}
          text={homePageInfo.tagLine || ""}
          placeholder={""}
          onChange={(text) =>
            setHomePageInfo({ ...homePageInfo, tagLine: text })
          }
        ></EditableText>
        <EditableText
          label={"Overview"}
          width={60}
          rows={6}
          text={homePageInfo.mainOverview || ""}
          placeholder={" "}
          onChange={(text) =>
            setHomePageInfo({ ...homePageInfo, mainOverview: text })
          }
        ></EditableText>
        <EditableText
          label={"Borrow Overview"}
          width={60}
          rows={6}
          text={homePageInfo.borrowOverview || ""}
          placeholder={""}
          onChange={(text) =>
            setHomePageInfo({ ...homePageInfo, borrowOverview: text })
          }
        ></EditableText>
        <EditableText
          label={"Volunteer Overview"}
          width={60}
          rows={6}
          text={homePageInfo.volunteerOverview || ""}
          placeholder={""}
          onChange={(text) =>
            setHomePageInfo({ ...homePageInfo, volunteerOverview: text })
          }
        ></EditableText>
        <EditableText
          label={"Donate Overview"}
          width={60}
          rows={6}
          text={homePageInfo.donateOverview || ""}
          placeholder={""}
          onChange={(text) =>
            setHomePageInfo({ ...homePageInfo, donateOverview: text })
          }
        ></EditableText>
        <EditableText
          label={"About us Overview"}
          width={60}
          rows={6}
          text={homePageInfo.aboutUsOverview || ""}
          placeholder={""}
          onChange={(text) =>
            setHomePageInfo({ ...homePageInfo, aboutUsOverview: text })
          }
        ></EditableText>
      </Body>
    </Page>
  );
}

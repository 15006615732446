import React, { useContext, useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import styled from "styled-components";
import { readInfoById, infoIDs } from "../../../api/Info";
import { submitVolunteerRequest } from "../../../api/VolunteerRequests";
import { LocationContext, locationIDs } from "../../../context/LocationContext";
import VolunteerInfoModel from "../../../models/infoModels/VolunteerInfoModel";

import {
  ButtonList,
  colorTheme,
  H1,
  H2,
  H3,
  P,
  Section,
} from "../../../theme/Styles";
import DownloadLink from "../../ui/buttons/DownloadLink";
import PrimaryButton from "../../ui/buttons/PrimaryButton";
import SecondaryButton from "../../ui/buttons/SecondaryButton";
import FormattedP from "../../ui/formattedP/FormattedP";
import {
  HeroContainer,
  HeroImage,
  HeroImageContainer,
  HeroSection,
  HeroText,
  HeroDescription,
  HeroOverviewContainer,
} from "../../ui/heroComponents/HeroStyledComponents";
import { TextInput } from "../../ui/input/textInput/TextInput";
import LoadingPage from "../../ui/loadingPage/LoadingPage";
import heroImage from "./assets/volunteer_hero.png";
import RegistrationModel from "./RegistrationModel";
import { formFields } from "./VolunteerFields";

const InputItem = styled.div`
  margin: auto;
  width: 25vw;
`;

const InputLabel = styled(P)`
  font-size: 1.2vw;
  margin: 0.3vw 0.2vw;
  font-weight: 600;
`;

export default function Volunteer() {
  const [show, setShow] = useState(false);
  const { location, setLocation } = useContext(LocationContext);

  const [volunteerInfo, setVolunteerInfo] = useState<VolunteerInfoModel | null>(
    null
  );

  useEffect(() => {
    setLocation(locationIDs.volunteer);
  }, []);

  useEffect(() => {
    readInfoById(infoIDs.volunteerInfo, (response) => {
      if (response) setVolunteerInfo(response);
    });
  }, []);

  const handleClose = () => setShow(false);

  if (!volunteerInfo) return <LoadingPage></LoadingPage>;
  return (
    <div>
      {/* <FullWidthHeroImage src={heroImage}></FullWidthHeroImage> */}
      <HeroSection backgroundColor={colorTheme.primary}>
        <HeroContainer reverseOnMobile>
          <HeroText>
            <H1 color={colorTheme.primaryLight}>
              Volunteer <br></br>with us{" "}
            </H1>
            {volunteerInfo.overview && (
              <HeroOverviewContainer>
                <FormattedP
                  color={colorTheme.white}
                  text={volunteerInfo.overview}
                ></FormattedP>
              </HeroOverviewContainer>
            )}
          </HeroText>
          <HeroImageContainer>
            <HeroImage src={heroImage}></HeroImage>
          </HeroImageContainer>
        </HeroContainer>
      </HeroSection>

      <Section>
        <H2 leftOnMobile color={colorTheme.primary}>
          Front Desk Volunteers
        </H2>
        {volunteerInfo.frontDeskVolunteersText && (
          <FormattedP
            text={volunteerInfo.frontDeskVolunteersText}
            width={34}
          ></FormattedP>
        )}
        <ButtonList>
          <SecondaryButton
            text={"Fill Out Expression of Interest"}
            onClick={() => setShow(true)}
          ></SecondaryButton>
        </ButtonList>
      </Section>

      <Section backgroundColor={colorTheme.extraLightGrey}>
        <H2 leftOnMobile color={colorTheme.secondary}>
          Commitee Members
        </H2>

        {volunteerInfo.commiteeMembersText && (
          <FormattedP
            text={volunteerInfo.commiteeMembersText}
            width={38}
          ></FormattedP>
        )}

        {volunteerInfo.commiteeMemberslinks &&
          volunteerInfo.commiteeMemberslinks.map((link, index) => {
            if (link.url && link.linkText)
              return (
                <DownloadLink
                  href={link.url}
                  text={link.linkText}
                ></DownloadLink>
              );
          })}
      </Section>

      <Section>
        <H2 leftOnMobile color={colorTheme.primary}>
          Board of Directors
        </H2>
        {volunteerInfo.directorsText && (
          <FormattedP
            text={volunteerInfo.directorsText}
            width={36}
          ></FormattedP>
        )}

        {volunteerInfo.directorsLinks &&
          volunteerInfo.directorsLinks.map((link, index) => {
            if (link.url && link.linkText)
              return (
                <DownloadLink
                  href={link.url}
                  text={link.linkText}
                ></DownloadLink>
              );
          })}
      </Section>

      <RegistrationModel show={show} onHide={handleClose}></RegistrationModel>
    </div>
  );
}

import React from "react";
import styled from "styled-components";
import InfoBlockModel from "../../../models/blockModels/InfoBlock";
import { devices } from "../../../theme/devices";
import { ButtonList, colorTheme, Link, P, H3 } from "../../../theme/Styles";
import DownloadLink from "../../ui/buttons/DownloadLink";
import FormattedP from "../../ui/formattedP/FormattedP";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  background-color: ${colorTheme.extraLightGrey};
  padding: 2vw;
  padding-bottom: 0;
  margin: 3vw auto;

  @media ${devices.mobile} {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 15vw 0vw;
  }
`;

const ImageContainer = styled.div`
  width: 25vw;
  margin: 2vw;
  @media ${devices.mobile} {
    width: 90%;
    height: auto;
    margin: 8vw auto;
  }
`;
const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Text = styled.div`
  width: 100%;
  margin: 2vw;

  @media ${devices.mobile} {
    width: 80%;
    margin: auto;
  }
`;

export default function InfoBlockView(props: { block: InfoBlockModel }) {
  return (
    <Container>
      {props.block.imageSrc && (
        <ImageContainer>
          <Image src={props.block.imageSrc}></Image>
        </ImageContainer>
      )}

      <Text>
        <H3>{props.block.header}</H3>
        {props.block.description && (
          <FormattedP width={40} text={props.block.description}></FormattedP>
        )}{" "}
        {props.block.links?.map((link, indes) => {
          if (link.fileName && link.url && link.linkText)
            return (
              <DownloadLink href={link.url} text={link.linkText}></DownloadLink>
            );
          return <Link href={link.url}>{link.linkText}</Link>;
        })}{" "}
      </Text>
    </Container>
  );
}

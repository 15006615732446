import React, { useContext, useState } from "react";
import styled from "styled-components";
import { LocationContext, locationIDs } from "../../../context/LocationContext";

import { devices } from "../../../theme/devices";
import { animationKeyFrames } from "../../../theme/keyframes";
import { colorTheme, HorizontalList, Link } from "../../../theme/Styles";
import brandImage from "./assets/logo.svg";

import menuIcon from "./assets/menu_icon.svg";
import menuCloseIcon from "./assets/menu_close_icon.svg";

const Container = styled(HorizontalList)`
  width: 100%;
  height: 5vw;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: ${colorTheme.white};
  padding-left: 3vw;
  z-index: 1000;

  @media ${devices.mobile} {
    height: 22vw;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 5vw;
  }
`;
const TopBarGap = styled.div`
  width: 100%;
  height: 1.5vw;
  background-color: ${colorTheme.white};
`;

const MenuLink = styled(Link)<{ selected?: boolean }>`
  font-size: 1.2vw;
  border: none;
  margin: 0.6vw 1vw;
  color: ${(props) =>
    props.selected ? colorTheme.primaryLight : colorTheme.primary};
  text-decoration: none;
  text-transform: none;

  &:hover {
    color: ${colorTheme.primaryLight};
    text-decoration: none;
  }
  @media ${devices.mobile} {
    font-size: 5.1vw;
    border: none;
    margin: 4vw 2vw;
    text-align: right;
    animation: ${animationKeyFrames.slideFromBottom} 0.5s;
  }
`;

const Brand = styled.img`
  height: 3vw;

  @media ${devices.mobile} {
    height: 12vw;
    padding-left: 3vw;
  }
`;

export const ButtonList = styled(HorizontalList)<{
  start?: boolean;
  end?: boolean;
  center?: boolean;
}>`
  justify-content: ${(props) =>
    props.end ? "flex-end" : props.center ? "center" : "flex-start"};
  margin-right: 2vw;

  @media ${devices.mobile} {
    display: block;
    flex-direction: column;
    justify-content: center;
    background-color: ${colorTheme.white};
    margin: 0;
    width: 45vw;
  }
`;

const MenuIconContainer = styled.div`
  display: none;
  @media ${devices.mobile} {
    display: flex;
    justify-content: flex-end;
    display: block;
    width: 50vw;
    animation: ${animationKeyFrames.fadeIn} 1s;
    img {
      margin-left: 35vw;
      margin-top: 3vw;
      width: 7vw;
    }
  }
`;

const Menu = styled.div<{ show?: boolean }>`
  display: flex;
  @media ${devices.mobile} {
    display: ${(props) => (props.show ? "flex" : "none")};
    flex-direction: column;
    padding: 8vw;
    animation: ${animationKeyFrames.fadeIn} 0.5s;
  }
`;

export default function TopBar() {
  const { location, setLocation } = useContext(LocationContext);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <Container>
      <a href={"./"}>
        {" "}
        <Brand src={brandImage}></Brand>
      </a>
      <ButtonList>
        <MenuIconContainer onClick={() => setShowMobileMenu(!showMobileMenu)}>
          {!showMobileMenu && <img src={menuIcon}></img>}
          {showMobileMenu && <img src={menuCloseIcon}></img>}
        </MenuIconContainer>

        <Menu show={showMobileMenu}>
          <MenuLink selected={location === locationIDs.home} href={"./"}>
            Home
          </MenuLink>
          <MenuLink
            selected={location === locationIDs.borrow}
            href={"./borrow"}
          >
            Borrow
          </MenuLink>
          <MenuLink
            selected={location === locationIDs.volunteer}
            href={"./volunteer"}
          >
            Volunteer
          </MenuLink>
          <MenuLink
            selected={location === locationIDs.donate}
            href={"./donate"}
          >
            Donate
          </MenuLink>
          <MenuLink
            selected={location === locationIDs.events}
            href={"./events"}
          >
            Events
          </MenuLink>
          <MenuLink
            selected={location === locationIDs.aboutus}
            href={"./aboutus"}
          >
            About Us
          </MenuLink>
        </Menu>
      </ButtonList>
    </Container>
  );
}

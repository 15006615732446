import React, { useEffect, useState } from "react";
import styled from "styled-components";

import InfoBlockModel from "../../../../../models/blockModels/InfoBlock";
import LinkModel from "../../../../../models/LinkModel";
import { colorTheme, HorizontalList } from "../../../../../theme/Styles";
import { HorizontalFlex } from "../../../Styles";
import EditableDownloadLink from "../editableDownloadLink/EditableDownloadLink";
import EditableImage from "../editableImage/EditableImage";
import EditableText from "../editableText/EditableText";
import EditableWebLink from "../editableWebLink/EditableWebLink";

const Container = styled.div<{ isDragging: boolean }>`
  width: 100%;
  height: auto;
  transform: scale(${(props) => (props.isDragging ? 0.8 : 1)});
  transition: transform 0.5s;
  /* background-color: ${colorTheme.lightGrey}; */
  overflow: hidden;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
`;

const ImageContainer = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  margin: 2vw;
  border-radius: 1.5vw;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  width: 60%;
  margin: 2vw 0;
`;

const AddLinksContainer = styled.div`
  /* display: flex;
  flex-direction: flex-start; */
`;

export default function InfoBlock(props: {
  imageRemovable?: boolean;
  block: InfoBlockModel;
  onBlockChange: (block: InfoBlockModel) => void;
  isDragging: boolean;
}) {
  const onLinkChange = (link: LinkModel, index: number) => {
    var block = { ...props.block };
    if (block.links) {
      block.links.splice(index, 1, link);
    } else {
      block.links = [link];
    }
    props.onBlockChange(block);
  };

  const onLinkRemove = (index: number) => {
    var block = { ...props.block };
    if (block.links) {
      block.links.splice(index, 1);
    }
    props.onBlockChange(block);
  };

  return (
    <Container isDragging={props.isDragging}>
      {!props.isDragging && (
        <ImageContainer>
          <EditableImage
            removable={props.imageRemovable}
            src={props.block.imageSrc}
            onFileChange={(url) => {
              if (url != null)
                props.onBlockChange({ ...props.block, imageSrc: url });
            }}
          ></EditableImage>
        </ImageContainer>
      )}
      <BodyContainer>
        <EditableText
          size={1.6}
          weight={600}
          width={50}
          rows={1}
          text={props.block.header || ""}
          placeholder={"Header"}
          onChange={(text) => {
            props.onBlockChange({ ...props.block, header: text });
          }}
        ></EditableText>
        {!props.isDragging && (
          <React.Fragment>
            <EditableText
              width={50}
              rows={10}
              text={props.block.description || ""}
              placeholder={"Description"}
              onChange={(text) => {
                props.onBlockChange({ ...props.block, description: text });
              }}
            ></EditableText>
            {props.block.links &&
              props.block.links.map((link, index) => {
                if (link.fileName) {
                  return (
                    <EditableDownloadLink
                      downloadLink={link}
                      onRemove={() => onLinkRemove(index)}
                      onChange={(link) => onLinkChange(link, index)}
                    ></EditableDownloadLink>
                  );
                } else
                  return (
                    <EditableWebLink
                      webLink={link}
                      onRemove={() => onLinkRemove(index)}
                      onChange={(link) => onLinkChange(link, index)}
                    ></EditableWebLink>
                  );
              })}

            <AddLinksContainer>
              <EditableWebLink
                webLink={{}}
                onRemove={() =>
                  onLinkRemove(props.block.links ? props.block.links.length : 0)
                }
                onChange={(link) =>
                  onLinkChange(
                    link,
                    props.block.links ? props.block.links.length : 0
                  )
                }
              ></EditableWebLink>

              <EditableDownloadLink
                downloadLink={{}}
                onRemove={() =>
                  onLinkRemove(props.block.links ? props.block.links.length : 0)
                }
                onChange={(link) =>
                  onLinkChange(
                    link,
                    props.block.links ? props.block.links.length : 0
                  )
                }
              ></EditableDownloadLink>
            </AddLinksContainer>
          </React.Fragment>
        )}{" "}
      </BodyContainer>
    </Container>
  );
}

import React from "react";
import styled from "styled-components";
import { colorTheme, P } from "../Styles";
import LoadingWidget from "./loadingWidget/LoadingWidget";

const Container = styled.div<{
  disable: boolean;
  small?: boolean;
  primary?: boolean;
}>`
  min-width: ${(props) => (props.small ? 7 : 15)}vw;
  height: ${(props) => (props.small ? 2 : 3)}vw;
  border-radius: ${(props) => (props.small ? 0.4 : 0.6)}vw;
  background-color: ${(props) =>
    props.disable
      ? colorTheme.darkGrey
      : props.primary
      ? colorTheme.primaryLight
      : colorTheme.lightGrey};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  /* padding: 2vw; */
  margin-left: ${(props) => (props.small ? 0.3 : 1)}vw;
  /* border: 1px solid
    ${(props) => (props.disable ? colorTheme.darkGrey : colorTheme.black)}; */

  &:hover {
    transform: scale(${(props) => (props.disable ? 1 : 1.02)});
  }
`;

const Text = styled(P)<{
  small?: boolean;
  primary?: boolean;
  disable?: boolean;
}>`
  text-align: center;
  letter-spacing: 0.025em;
  color: ${(props) =>
    props.disable
      ? colorTheme.lightGrey
      : props.primary
      ? colorTheme.black
      : colorTheme.black};
  /* text-transform: uppercase; */

  font-weight: 700;
  font-size: ${(props) => (props.small ? 0.8 : 1.1)}vw;
`;

const Icon = styled.img`
  width: 1vw;
  height: 1vw;
  margin-right: 1vw;
`;

interface buttonProps {
  text: string;
  loading?: boolean;
  onClick: () => void;
  disable?: boolean;
  imageSrc?: string;
  small?: boolean;
  primary?: boolean;
}

export default function Button(props: buttonProps) {
  return (
    <Container
      disable={props.disable ? props.disable : false}
      small={props.small}
      primary={props.primary}
      onClick={() => {
        if (!props.disable) props.onClick();
      }}
    >
      {!props.loading && props.imageSrc && <Icon src={props.imageSrc}></Icon>}
      {!props.loading && (
        <Text
          primary={props.primary}
          disable={props.disable}
          small={props.small}
        >
          {props.text}
        </Text>
      )}

      {props.loading && <LoadingWidget></LoadingWidget>}
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { H3, colorTheme, TextArea } from "../../../Styles";

const Container = styled.div`
  /* display: flex;
  align-items: center; */
`;

const TextEdit = styled(TextArea)<{
  size?: number;
  weight?: number;
  width?: number;
  backgroundColor?: string;
  color?: string;
}>`
  background-color: ${(props) => props.backgroundColor || colorTheme.lightGrey};
  color: ${(props) => props.color || colorTheme.black};
  width: ${(props) => props.width || 30}vw;
  font-style: normal;
  font-weight: ${(props) => props.weight || 400};
  font-size: ${(props) => props.size + "vw" || "auto"};
  line-height: 150%;
  margin-bottom: 2vw;
  padding: 2vw;
  resize: none;
  outline: none;
  border: none;
  overflow: visible;
  border-radius: 1vw;
  &:hover {
    border-left: 3px solid ${(props) => props.color || colorTheme.black};
  }
  &:focus {
    text-decoration: none;
  }
`;

const Label = styled(H3)`
  font-weight: 600;
  font-size: 1vw;
  margin-bottom: 0.6vw;
  margin-left: 0.5vw;

  color: ${colorTheme.black};
`;

const TextContainer = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  height: auto;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
  background-color: none;
`;

interface editableTextProps {
  label?: string;
  placeholder: string;
  text: string | null;
  size?: number;
  weight?: number;
  width?: number;
  rows: number;
  marginTop?: string;
  marginBottom?: string;
  backgroundColor?: string;
  color?: string;
  onChange: (text: string) => void;
  onCancel?: (text: string) => void;
}

export default function EditableText(props: editableTextProps) {
  return (
    <Container>
      {props.label && <Label>{props.label}</Label>}
      <TextEdit
        weight={props.weight}
        width={props.width}
        size={props.size}
        placeholder={props.placeholder}
        rows={props.rows}
        color={props.color}
        backgroundColor={props.backgroundColor}
        value={props.text ? props.text : ""}
        onChange={(e) => props.onChange(e.target.value)}
      ></TextEdit>
    </Container>
  );
}

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { infoIDs, readInfoById, updateInfoById } from "../../../../api/Info";
import { blockTypes } from "../../../../constants/BlockTypes";
import { AlertContext } from "../../../../context/AlertContext";
import DonationsInfoModel from "../../../../models/infoModels/DonationsInfoModel";
import ServiceInfoModel from "../../../../models/infoModels/ServiceInfoModel";
import {
  Body,
  H2,
  H3,
  HorizontalFlex,
  Link,
  Page,
  StickyHeader,
  VerticalCushion,
} from "../../Styles";
import Button from "../../ui/Button";
import BlockList from "../../ui/editables/editableBlocks/BlockList";
import EditableText from "../../ui/editables/editableText/EditableText";

export default function Service() {
  const [service, setService] = useState<ServiceInfoModel>({});
  const { alert, setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const update = () => {
    setLoading(true);
    updateInfoById(infoIDs.serviceInfo, service, (response) => {
      readServiceInfo();
      setAlert({ visible: true, message: response.message });
      setLoading(false);
    });
  };

  useEffect(() => {
    readServiceInfo();
  }, []);

  const readServiceInfo = () => {
    readInfoById(infoIDs.serviceInfo, (response) => {
      if (response) setService(response);
    });
  };

  return (
    <Page>
      <Body>
        <VerticalCushion small> </VerticalCushion>
        <Link href={"/portal"}>Dashboard</Link>{" "}
        <StickyHeader>
          <H2>Borrow Page</H2>
          <Button
            loading={loading}
            primary
            text={"Update"}
            onClick={update}
          ></Button>
        </StickyHeader>
        <VerticalCushion small> </VerticalCushion>
        <EditableText
          label={"Page Overview"}
          width={50}
          rows={5}
          text={service.overview || ""}
          placeholder={""}
          onChange={(text) => setService({ ...service, overview: text })}
        ></EditableText>
        <EditableText
          label={"Our Borrowing Process is Easy"}
          width={50}
          rows={6}
          text={service.processText || ""}
          placeholder={""}
          onChange={(text) => setService({ ...service, processText: text })}
        ></EditableText>
        <EditableText
          label={"Service Address"}
          rows={5}
          text={service.address || ""}
          placeholder={""}
          onChange={(text) => setService({ ...service, address: text })}
        ></EditableText>
        <EditableText
          label={"Google Maps Embed Link"}
          width={80}
          rows={3}
          text={service.googleMapLink || ""}
          placeholder={""}
          onChange={(text) => setService({ ...service, googleMapLink: text })}
        ></EditableText>
        <EditableText
          label={"Email"}
          rows={1}
          text={service.email || ""}
          placeholder={""}
          onChange={(text) => setService({ ...service, email: text })}
        ></EditableText>
        <EditableText
          label={"Service Phone Number"}
          rows={1}
          text={service.phone || ""}
          placeholder={""}
          onChange={(text) => setService({ ...service, phone: text })}
        ></EditableText>
        <EditableText
          label={"Hours of Operation"}
          width={50}
          rows={5}
          text={service.hours || ""}
          placeholder={""}
          onChange={(text) => setService({ ...service, hours: text })}
        ></EditableText>
        <VerticalCushion small></VerticalCushion>
        <VerticalCushion small>
          <H3>Holidays</H3>
        </VerticalCushion>
        <EditableText
          label={"January"}
          width={80}
          rows={3}
          text={service.holidays?.january || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, january: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"February"}
          width={80}
          rows={3}
          text={service.holidays?.february || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, february: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"March"}
          width={80}
          rows={3}
          text={service.holidays?.march || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, march: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"April"}
          width={80}
          rows={3}
          text={service.holidays?.april || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, april: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"May"}
          width={80}
          rows={3}
          text={service.holidays?.may || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, may: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"June"}
          width={80}
          rows={3}
          text={service.holidays?.june || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, june: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"July"}
          width={80}
          rows={3}
          text={service.holidays?.july || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, july: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"August"}
          width={80}
          rows={3}
          text={service.holidays?.august || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, august: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"September"}
          width={80}
          rows={3}
          text={service.holidays?.september || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, september: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"October"}
          width={80}
          rows={3}
          text={service.holidays?.october || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, october: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"November"}
          width={80}
          rows={3}
          text={service.holidays?.november || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, november: text },
            })
          }
        ></EditableText>
        <EditableText
          label={"December"}
          width={80}
          rows={3}
          text={service.holidays?.december || ""}
          placeholder={""}
          onChange={(text) =>
            setService({
              ...service,
              holidays: { ...service.holidays, december: text },
            })
          }
        ></EditableText>
        <VerticalCushion small>
          <H3>FAQs</H3>
          <BlockList
            blockTypes={[blockTypes.FAQ]}
            blocks={service.FAQs || []}
            onBlockListChange={(blocks) =>
              setService({ ...service, FAQs: blocks })
            }
          ></BlockList>
        </VerticalCushion>
      </Body>
    </Page>
  );
}

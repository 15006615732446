import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { readEventById, updateEventById } from "../../../../../api/Events";
import { blockTypes } from "../../../../../constants/BlockTypes";
import { AlertContext } from "../../../../../context/AlertContext";
import Event from "../../../../../models/Event";
import {
  Body,
  ButtonList,
  colorTheme,
  H2,
  H3,
  Link,
  Page,
  StickyHeader,
  VerticalCushion,
} from "../../../Styles";
import Button from "../../../ui/Button";
import BlockList from "../../../ui/editables/editableBlocks/BlockList";
import EditableImage from "../../../ui/editables/editableImage/EditableImage";
import EditableText from "../../../ui/editables/editableText/EditableText";

const HeroSection = styled.div`
  /* display: flex;
  padding: 3vw 0;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%; */
  margin: 5vw auto;
  position: relative;
`;

const HeroTextContainer = styled.div`
  /* background-color: ${colorTheme.primary}; */
  margin: 3vw auto;
`;

const Header = styled.div`
  width: 100%;
  min-height: 5vw;
  background-color: ${colorTheme.extraLightGrey};
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
`;

const Title = styled.div`
  width: 40vw;
  margin-left: 5vw;
`;

const ButtonContainer = styled.div`
  width: 60vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2vw;
`;

export default function CreateEvent(
  props: RouteComponentProps<{ id: string }>
) {
  const [event, setEvent] = useState<Event>({});
  const [isLoading, setIsLoading] = useState(false);

  const { alert, setAlert } = useContext(AlertContext);

  useEffect(() => {
    readEventById(props.match.params.id, (response) => {
      if (response) {
        setEvent(response);
      }
    });
  }, []);

  if (!event) return null;

  return (
    <Page>
      <Body>
        <VerticalCushion small></VerticalCushion>
        <Link href={"/portal/allevents"}>All Events</Link>

        <StickyHeader>
          <H2 width={1000}>Events</H2>
          <ButtonList>
            <Button
              primary
              text={"Save Event"}
              onClick={() => {
                setIsLoading(true);
                updateEventById(props.match.params.id, event, (response) => {
                  setAlert({ visible: true, message: response.message });
                  setIsLoading(false);
                });
              }}
              loading={isLoading}
            ></Button>
          </ButtonList>
        </StickyHeader>

        <HeroSection>
          <div>
            <EditableImage
              height={"40vw"}
              src={event.heroImage}
              onFileChange={(url) => {
                setEvent({ ...event, heroImage: url });
              }}
            ></EditableImage>

            <HeroTextContainer>
              <EditableText
                label={"Event Name"}
                size={2}
                weight={700}
                width={70}
                rows={1}
                text={event.title || ""}
                placeholder={"Event title"}
                onChange={(text) => {
                  setEvent({ ...event, title: text });
                }}
              ></EditableText>

              <EditableText
                label={"Short Description"}
                width={40}
                rows={6}
                text={event.description || ""}
                placeholder={"Event description"}
                onChange={(text) => {
                  setEvent({ ...event, description: text });
                }}
              ></EditableText>

              <EditableText
                label={"Date and Time"}
                rows={1}
                width={40}
                text={event.date || ""}
                size={1.5}
                weight={700}
                placeholder={"Event date"}
                onChange={(text) => {
                  setEvent({ ...event, date: text });
                }}
              ></EditableText>

              <EditableText
                label={"Location"}
                rows={1}
                width={40}
                text={event.location || ""}
                size={1.5}
                weight={700}
                placeholder={"Event location"}
                onChange={(text) => {
                  setEvent({ ...event, location: text });
                }}
              ></EditableText>
            </HeroTextContainer>
          </div>

          {/* <FileInput
        onUpload={(url) => alert(url)}
        onCloseTrigger={() => {}}
      ></FileInput> */}
        </HeroSection>
        <BlockList
          blockTypes={[blockTypes.INFO, blockTypes.IMAGE]}
          blocks={event.blocks || []}
          onBlockListChange={(blocks) => setEvent({ ...event, blocks: blocks })}
        ></BlockList>
      </Body>
    </Page>
  );
}

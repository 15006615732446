import styled from "styled-components";
import { devices } from "./devices";
import { animationKeyFrames } from "./keyframes";

export const colorTheme = {
  primary: "#394E80",
  primaryDark: "#657db6",

  primaryVariant: "#6a7eaf",
  primaryLight: "#0DD3FF",
  secondary: "#F48149",
  secondaryLight: "#FFAD86",
  accent: "#F78F1E",
  accentLight: "#F78F1E",
  white: "#ffffff",
  black: "#082332",
  lightGrey: "#eeeeee",
  extraLightGrey: "#F0F5ED",
  darkGrey: "#B2B2B2",
  veryDarkGrey: "#5e5e5e",

  error: "#F59D9D",
};

export const H1 = styled.h1<{ color?: string; width?: number }>`
  font-family: "Poppins", sans-serif;
  font-size: 3.7vw;
  font-weight: 700;
  text-align: left;
  line-height: 1.12;
  letter-spacing: -0.06vw;
  width: ${(props) => (props.width ? props.width + "vw" : "auto")};
  color: ${(props) => props.color || colorTheme.primary};
  @media ${devices.mobile} {
    font-size: 12vw;
    margin: 10vw 0;
    width: 100%;
  }
`;

export const H2 = styled.h1<{
  color?: string;
  width?: number;
  center?: boolean;
  leftOnMobile?: boolean;
}>`
  font-family: "Poppins", sans-serif;
  font-size: 3.5vw;
  font-weight: 700;
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: 1.2;
  color: ${(props) => props.color || colorTheme.primary};
  width: ${(props) => (props.width ? props.width + "vw" : "auto")};

  /* max-width: ${(props) => props.width || 100}vw; */
  @media ${devices.mobile} {
    font-size: 9.5vw;
    margin: 10vw 0;
    text-align: ${(props) => (props.leftOnMobile ? "left" : "center")};
    width: 100%;
  }
`;

export const H3 = styled.h1<{
  color?: string;
  width?: number;
  center?: boolean;
  leftOnMobile?: boolean;
}>`
  font-family: "Poppins", sans-serif;
  font-size: 2.5vw;
  font-weight: 700;
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: 1.2;
  color: ${(props) => props.color || colorTheme.primary};
  max-width: ${(props) => props.width || 100}vw;
  @media ${devices.mobile} {
    font-size: 9.5vw;
    margin-bottom: 10vw;
  }
`;

export const H4 = styled.h1<{
  color?: string;
  width?: number;
  center?: boolean;
}>`
  font-family: "Poppins", sans-serif;
  font-size: 1.5vw;
  margin-top: 2vw;
  font-weight: 700;
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: 1.2;
  color: ${(props) => props.color || colorTheme.primary};
  max-width: ${(props) => props.width || 100}vw;
  @media ${devices.mobile} {
    font-size: 6.5vw;
  }
`;

export const P = styled.p<{
  color?: string;
  width?: number;
  center?: boolean;
  marginTop?: number;
  strong?: boolean;
  centerOnMobile?: boolean;
}>`
  font-family: "Inter", sans-serif;
  font-size: 1.2vw;
  font-weight: ${(props) => (props.strong ? 700 : 500)};
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: 1.5;
  margin: ${(props) => props.marginTop || 1.2}vw 0;
  color: ${(props) => props.color || colorTheme.primary};
  width: ${(props) => (props.width ? props.width + "vw" : "auto")};
  /* max-width: ${(props) => props.width || 100}vw; */

  @media ${devices.mobile} {
    width: 100%;
    font-size: 5vw;
    font-weight: ${(props) => (props.strong ? 700 : 400)};
    text-align: ${(props) =>
      props.center || props.centerOnMobile ? "center" : "left"};

    br {
      display: none;
    }
  }
`;

export const P2 = styled.p<{
  color?: string;
  width?: number;
  center?: boolean;
}>`
  font-family: "Poppins", sans-serif;
  font-size: 1.2vw;
  font-weight: 400;
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: 1.5;
  margin: 1.2vw 0;
  color: ${(props) => props.color || colorTheme.primary};
  width: ${(props) => (props.width ? props.width + "vw" : "auto")};

  @media ${devices.mobile} {
    font-size: 5vw;
    width: 100%;
  }
`;

export const Section = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${(props) => props.backgroundColor || colorTheme.white};
  padding: 4vw 8vw;
  animation: ${animationKeyFrames.fadeIn} 2s;

  @media ${devices.mobile} {
    padding: 10vw 7vw;
  }
`;

export const Centered = styled.div`
  width: 100%;
  /* display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center; */

  @media ${devices.mobile} {
    align-items: none;
  }
`;

export const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  padding: 0.5vw 1.3vw;
  margin-right: 1.2vw;
  text-transform: uppercase;
  border-radius: 0.8vw;
  cursor: pointer;
  transition: transform linear 0.1s;
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: 600;
  /* box-shadow: 2px 2px 5px ${colorTheme.primaryDark}; */

  &:hover {
    transform: scale(1.02);
  }
  /* min-width: 10vw; */

  @media ${devices.mobile} {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 7vw;
    font-size: 4vw;
    padding: 4vw 1.3vw;
    border-radius: 4vw;
    /* box-shadow: 1px 3px 5px ${colorTheme.primaryDark}; */
  }
`;

export const Link = styled.a`
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.2vw;
  font-weight: 700;
  /* border-bottom: 1px solid ${colorTheme.primaryLight}; */
  color: ${colorTheme.primary};
  /* text-transform: uppercase; */
  text-decoration: underline;
  margin-bottom: 2vw;
  margin-right: 3vw;
  cursor: pointer;
  &:hover {
    color: ${colorTheme.primaryLight};
  }

  @media ${devices.mobile} {
    font-size: 4.2vw;
    margin-bottom: 5vw;
  }
`;

export const HorizontalList = styled.div`
  display: flex;
  /* justify-content: space-around; */
`;

export const ButtonList = styled(HorizontalList)<{
  start?: boolean;
  end?: boolean;
  center?: boolean;
  smallTopMargin?: boolean;
  smallBottomMargin?: boolean;
}>`
  margin-top: ${(props) => (props.smallTopMargin ? 2.5 : 3.5)}vw;
  margin-bottom: ${(props) => (props.smallBottomMargin ? 0 : 2.5)}vw;

  justify-content: ${(props) =>
    props.end ? "flex-end" : props.center ? "center" : "flex-start"};
  flex-wrap: wrap;

  @media ${devices.mobile} {
    justify-content: center;
    flex-direction: column;
    justify-content: ${(props) =>
      props.end ? "center" : props.center ? "center" : "center"};
    margin-top: ${(props) => (props.smallTopMargin ? 5 : 15)}vw;
    margin-bottom: ${(props) => (props.smallBottomMargin ? 0.3 : 10)}vw;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  margin: 3vw auto;

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const MapContainer = styled.div`
  position: relative;
  padding-bottom: 30%; /* 16:9 */
  margin: 3vw auto;
  border: none;

  @media ${devices.mobile} {
    padding-bottom: 100%; /* 16:9 */
  }

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
export const TextArea = styled.textarea`
  font-family: "Poppins", sans-serif;
`;

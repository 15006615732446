import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import {
  createEvent,
  deleteEventById,
  readAllEvents,
  updateEventById,
} from "../../../../../api/Events";
import { AlertContext } from "../../../../../context/AlertContext";
import Event from "../../../../../models/Event";
import { ButtonList, colorTheme, H2, H3, P } from "../../../Styles";
import {
  Page,
  VerticalCushion,
  StickyHeader,
  Link,
  Body,
} from "../../../Styles";
import Button from "../../../ui/Button";
import AddButton from "../../../ui/buttons/addButton/AddButton";
import DeleteButton from "../../../ui/deleteButton/DeleteButton";
import ToggleSwitch from "../../../ui/toggleSwitch/ToggleSwitch";

const EventsList = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const EventContainer = styled.div`
  width: 45%;
  /* background-color: ${colorTheme.extraLightGrey}; */
  margin: 5vw auto;
  padding-bottom: 3vw;
  border-radius: 2vw;
  overflow: hidden;
  border: 1px solid ${colorTheme.darkGrey};
`;
const EventImageContainer = styled.div`
  width: 100%;
  max-height: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorTheme.extraLightGrey};
  overflow: hidden;
`;

const EventImage = styled.img`
  width: 100%;
`;
const EventTitle = styled(H2)`
  margin-left: 3vw;
  margin-top: 3vw;
  font-size: 1.8vw;
`;

const EventDescription = styled(P)`
  margin: 1vw 3vw;
  height: 8vw;
  font-weight: 400;
  font-size: 1vw;
  overflow: hidden;
`;

const ToggleButtonContainer = styled.div`
  margin: 3vw;
  justify-content: flex-start;

  /* padding: 0 3vw; */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2.5vw;

  /* padding: 0 3vw; */
`;

const EditButtonContainer = styled.div`
  /* display: flex; */
  width: 3vw;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
interface RouterParams {
  id: string;
}
export default function AllEvents(props: RouteComponentProps<RouterParams>) {
  const [events, setevents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { alert, setAlert } = useContext(AlertContext);

  useEffect(() => {
    readEvents();
  }, []);

  const readEvents = () => {
    readAllEvents((events) => {
      if (events) {
        setevents(events);
      }
    });
  };

  const createNewEvent = () => {
    setIsLoading(true);
    createEvent({}, (response) => {
      if (response) {
        props.history.push("./createevent/" + response.id);
      }
      setIsLoading(false);
    });
  };

  const onPublishClick = (
    isPublished: boolean,
    event: Event,
    index: number
  ) => {
    event.isPublished = isPublished;
    if (event.id) {
      updateEventById(event.id, event, (response) => {
        var eventsTemp = [...events];
        eventsTemp.splice(index, 1, event);
        setevents(eventsTemp);
        setAlert({ visible: true, message: response.message });
      });
    }
  };

  const onPastEventClick = (
    isPastEvent: boolean,
    event: Event,
    index: number
  ) => {
    event.isPastEvent = isPastEvent;
    if (event.id) {
      updateEventById(event.id, event, (response) => {
        var eventsTemp = [...events];
        eventsTemp.splice(index, 1, event);
        setevents(eventsTemp);
        setAlert({ visible: true, message: response.message });
      });
    }
  };

  const onFeatureInHomePageClick = (
    isFeatured: boolean,
    event: Event,
    index: number
  ) => {
    event.isFeatured = isFeatured;
    if (event.id) {
      updateEventById(event.id, event, (response) => {
        var eventsTemp = [...events];
        eventsTemp.splice(index, 1, event);
        setevents(eventsTemp);
        setAlert({ visible: true, message: response.message });
      });
    }
  };

  const deleteEvent = (id: string) => {
    deleteEventById(id, (response) => {
      if (response) {
        readEvents();
        alert(JSON.stringify(response));
      }
    });
  };

  return (
    <Page>
      <Body>
        <VerticalCushion small></VerticalCushion>
        <Link href={"/portal"}>Dashboard</Link>

        <StickyHeader>
          <H2 width={1000}>Events</H2>
          <ButtonList>
            <AddButton
              primary
              text={"Create Event"}
              onClick={createNewEvent}
            ></AddButton>
          </ButtonList>
        </StickyHeader>
        <EventsList>
          {events.map((event, index) => {
            if (event.id && event.title)
              return (
                <EventContainer>
                  <EventImageContainer>
                    {event.heroImage && (
                      <EventImage src={event.heroImage}></EventImage>
                    )}
                  </EventImageContainer>
                  {event.title && <EventTitle>{event.title}</EventTitle>}
                  {event.description && (
                    <EventDescription>{event.description}</EventDescription>
                  )}
                  <ToggleButtonContainer>
                    <ToggleSwitch
                      label={"Publish"}
                      isChecked={event.isPublished || false}
                      onChange={(ev) => {
                        onPublishClick(ev.target.checked, event, index);
                      }}
                    ></ToggleSwitch>

                    <ToggleSwitch
                      label={"Past Event"}
                      isChecked={event.isPastEvent || false}
                      onChange={(ev) => {
                        onPastEventClick(ev.target.checked, event, index);
                      }}
                    ></ToggleSwitch>

                    <ToggleSwitch
                      label={"Feature in Home Page"}
                      isChecked={event.isFeatured || false}
                      onChange={(ev) => {
                        onFeatureInHomePageClick(
                          ev.target.checked,
                          event,
                          index
                        );
                      }}
                    ></ToggleSwitch>
                  </ToggleButtonContainer>
                  <ButtonContainer>
                    <EditButtonContainer>
                      <Button
                        text={"Edit Event"}
                        onClick={() =>
                          props.history.push("./createevent/" + event.id)
                        }
                      ></Button>
                    </EditButtonContainer>
                    <DeleteButtonContainer>
                      <DeleteButton
                        onDeleteTriggered={() => deleteEvent(event.id || "")}
                      ></DeleteButton>
                    </DeleteButtonContainer>
                  </ButtonContainer>
                </EventContainer>
              );
          })}
        </EventsList>
      </Body>
    </Page>
  );
}

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { infoIDs, readInfoById } from "../../../api/Info";
import { LocationContext, locationIDs } from "../../../context/LocationContext";
import AboutUsInfoModel from "../../../models/infoModels/AboutUsInfoModel";

import {
  Centered,
  colorTheme,
  H1,
  H2,
  P,
  Section,
  VideoContainer,
} from "../../../theme/Styles";
import FormattedList from "../../ui/formattedList/FormattedList";
import FormattedP from "../../ui/formattedP/FormattedP";
import {
  HeroSection,
  HeroContainer,
  HeroText,
} from "../../ui/heroComponents/HeroStyledComponents";
import logos from "./assets/logos.png";

import historyImage from "./assets/history.jpg";
import { devices } from "../../../theme/devices";
import LoadingPage from "../../ui/loadingPage/LoadingPage";

const Logos = styled.img`
  width: 48%;
  margin: 3vw auto;

  @media ${devices.mobile} {
    width: 100%;
    margin: 10vw 0;
  }
`;

const HistoryContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const HistoryImageContainer = styled.div`
  width: 50vw;
  margin-left: 5vw;
  height: auto;

  @media ${devices.mobile} {
    width: 100%;
    margin: 10vw 0;
  }

  img {
    width: 100%;
  }
`;

export default function AboutUs() {
  const [aboutUsInfo, setAboutUsInfo] = useState<AboutUsInfoModel | null>(null);

  const { location, setLocation } = useContext(LocationContext);

  useEffect(() => {
    setLocation(locationIDs.aboutus);
  }, []);

  useEffect(() => {
    readInfoById(infoIDs.aboutUsInfo, (response) => {
      if (response) setAboutUsInfo(response);
    });
  }, []);

  if (!aboutUsInfo) return <LoadingPage></LoadingPage>;
  return (
    <div>
      <Section>
        <H1 width={50}>{aboutUsInfo.mission}</H1>
        <P marginTop={3} width={35}>
          {aboutUsInfo.vision}
        </P>
      </Section>

      {aboutUsInfo.history && (
        <Section backgroundColor={colorTheme.primary}>
          <HistoryContainer>
            <div>
              <H2 leftOnMobile color={colorTheme.white}>
                Our history
              </H2>
              <FormattedP
                text={aboutUsInfo.history}
                color={colorTheme.white}
                width={30}
              ></FormattedP>
            </div>{" "}
            <HistoryImageContainer>
              <img src={historyImage}></img>
            </HistoryImageContainer>
          </HistoryContainer>
        </Section>
      )}

      <Section backgroundColor={colorTheme.extraLightGrey}>
        <H2 leftOnMobile>Our organization</H2>
        {aboutUsInfo.organizationSummery && (
          <FormattedP
            text={aboutUsInfo.organizationSummery}
            width={35}
          ></FormattedP>
        )}

        {aboutUsInfo.executiveDirector && (
          <FormattedList
            text={aboutUsInfo.executiveDirector}
            listHeader={"Executive Director"}
          ></FormattedList>
        )}

        {aboutUsInfo.leadVolunteers && (
          <FormattedList
            text={aboutUsInfo.leadVolunteers}
            listHeader={"Lead Volunteers"}
          ></FormattedList>
        )}

        {aboutUsInfo.boardOfDirectors && (
          <FormattedList
            text={aboutUsInfo.boardOfDirectors}
            listHeader={"Board of Directors"}
          ></FormattedList>
        )}

        {aboutUsInfo.members && (
          <FormattedList
            text={aboutUsInfo.members}
            listHeader={"Members"}
          ></FormattedList>
        )}
      </Section>

      {aboutUsInfo.videoTourLink && (
        <Section backgroundColor={colorTheme.extraLightGrey}>
          <Centered>
            <H2 leftOnMobile center color={colorTheme.secondary}>
              Take a video tour of The Closet!
            </H2>
          </Centered>
          <VideoContainer>
            <iframe
              width="560"
              height="315"
              src={aboutUsInfo.videoTourLink}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </VideoContainer>
        </Section>
      )}

      {(aboutUsInfo.supportSystemText || aboutUsInfo.supportSystemImage) && (
        <Section>
          <H2 leftOnMobile color={colorTheme.primary}>
            Our support system
          </H2>

          {aboutUsInfo.supportSystemText && (
            <FormattedP
              text={aboutUsInfo.supportSystemText}
              width={40}
            ></FormattedP>
          )}
          {aboutUsInfo.supportSystemImage && (
            <Logos src={aboutUsInfo.supportSystemImage}></Logos>
          )}
        </Section>
      )}
    </div>
  );
}

import { readDocumentById, updateDocumentById } from '../firestoreDB/FirestoreDB';

const PAGES_COLLECTION = "info"

// export const createEquipment = (data: EquipmentModel, callback: (docRef: any) => void) => {
//     addToCollection(PAGES_COLLECTION, data, callback)
// }

export const infoIDs =
{
    homeInfo: "home",
    donationsInfo: "donations",
    volunteerInfo: "volunteer",
    serviceInfo: "service",
    aboutUsInfo:"aboutus"

}

export const updateInfoById = (id: string, data: any, callback: (response: any) => void) => {
    updateDocumentById(PAGES_COLLECTION, id, data, callback);
}

export const readInfoById = (id: string, callback: (response: any) => void) => {
    readDocumentById(PAGES_COLLECTION, id, callback)
}

// export const readAllEquipment = (callback: (response: any) => void) => {
//     readAllDocumentsOrderBy(PAGES_COLLECTION, "name", callback)
// }
import styled from "styled-components"
import { devices } from "../../../../theme/devices"
import { colorTheme } from "../../../../theme/Styles"

export const TextInput = styled.textarea<{
    color?: string;
    width?: number;
  center?: boolean;
    error?:boolean
}>`
  font-family: "Poppins", sans-serif;
  font-size: 1.2vw;
  font-weight: 400;
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: 1.5;
  margin: 0vw;
  color: ${(props) => props.color || colorTheme.primary};
  width: ${props=>props.width||"25"}vw;
  border: ${props => props.error?2:1}px solid ${props => props.error? colorTheme.error: props.color || colorTheme.primary};
  border-radius: 0.5vw;
   resize: none;
  outline: none;
  padding:1vw;
  overflow:hidden;

  @media ${devices.mobile} {
    font-size: 4vw;
    margin:auto;
    width:100%;
    padding:3vw;
  }`;
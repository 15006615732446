import styled from "styled-components";
import ImageBlockModel from "../../../../../models/blockModels/ImageBlock";
import { colorTheme } from "../../../../../theme/Styles";
import EditableImage from "../editableImage/EditableImage";

const Container = styled.div<{ isDragging: boolean }>`
  width: 100%;
  height: auto;
  transform: scale(${(props) => (props.isDragging ? 0.8 : 1)});
  transition: transform 0.5s;
  display: flex;
  justify-content: center;
  /* background-color: ${colorTheme.lightGrey}; */
`;

export default function ImageBlock(props: {
  block: ImageBlockModel;
  onBlockChange: (block: ImageBlockModel) => void;
  isDragging: boolean;
}) {
  const onURLChange = (url: string | null) => {
    var block = { ...props.block };
    if (url) block.url = url;
    props.onBlockChange(block);
  };

  return (
    <Container isDragging={props.isDragging}>
      <EditableImage
        width={"70vw"}
        height={props.isDragging ? "10vw" : "auto"}
        src={props.block.url || null}
        onFileChange={(url) => onURLChange(url)}
      ></EditableImage>
    </Container>
  );
}

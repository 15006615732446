import React, { useState } from "react";
import styled from "styled-components";

import { blockTypes } from "../../../../../constants/BlockTypes";
import FAQBlockModel from "../../../../../models/blockModels/FAQBlock";
import ImageBlockModel from "../../../../../models/blockModels/ImageBlock";
import InfoBlockModel from "../../../../../models/blockModels/ImageBlock";
import { colorTheme } from "../../../../../theme/Styles";
import RemoveButton from "../../removeButton/RemoveButton";
import BlockToolBar from "./BlockToolBar";
import FAQBlock from "./FAQBlock";
import ImageBlock from "./ImageBlock";
import InfoBlock from "./InfoBlock";

const Container = styled.div<{ isDragging: boolean }>`
  width: ${(props) => (props.isDragging ? "50vw" : "90vw")};
  height: auto;
  margin: auto;
  margin-bottom: 5vw;
`;

const DraggableWrapper = styled.div`
  position: relative;
  padding: 0vw 2vw;
  /* padding-bottom: 5vw; */
  overflow: hidden;
  margin: 6vw auto;
  border-radius: 2vw;
  /* background-color: ${colorTheme.extraLightGrey}; */
  border: 1px solid ${colorTheme.darkGrey};
  &:hover {
    border: 3px solid ${colorTheme.darkGrey};
  }
`;

const DeleteButtonContainer = styled.div`
  position: absolute;
  right: 0.5vw;
  top: 1vw;
`;

const DragZone = styled.div`
  width: 10%;
  height: 1.4vw;
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
  background-color: ${colorTheme.darkGrey};
  margin: 0 auto;
  padding: 0;
  margin-bottom: 3vw;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
`;

const DropTarget = styled.div<{ dragOver: boolean }>`
  width: 100%;
  height: 1vw;
  margin: 2vw 0;
  background-color: ${(props) =>
    props.dragOver ? colorTheme.accentLight : colorTheme.lightGrey};

  &:hover {
    background-color: ${colorTheme.accentLight};
  }
`;

export default function BlockList(props: {
  blockTypes?: number[];
  infoBlockImageRemovable?: boolean;
  blocks: (InfoBlockModel | ImageBlockModel)[];
  onBlockListChange: (blocks: (InfoBlockModel | ImageBlockModel)[]) => void;
}) {
  const [dragIndex, setDragIndex] = useState(-1);
  const [dragOverIndex, setDragOverIndex] = useState(-1);
  const [mouseOverIndex, setMouseOverIndex] = useState(-1);

  const updateBlock = (index: number, block: InfoBlockModel) => {
    var updatedBlocks = [...props.blocks];
    updatedBlocks.splice(index, 1, block);
    props.onBlockListChange(updatedBlocks);
  };

  const addNewBlock = (block: InfoBlockModel) => {
    var updatedBlocks = [...props.blocks, block];
    props.onBlockListChange(updatedBlocks);
  };

  const deleteBlock = (index: number) => {
    var updatedBlocks = [...props.blocks];
    updatedBlocks.splice(index, 1);
    props.onBlockListChange(updatedBlocks);
  };

  const renderInfoBlock = (index: number, block: InfoBlockModel) => {
    return (
      <InfoBlock
        imageRemovable={props.infoBlockImageRemovable}
        isDragging={dragIndex >= 0}
        block={block}
        onBlockChange={(changedBlock) => updateBlock(index, changedBlock)}
      ></InfoBlock>
    );
  };

  const renderImageBlock = (index: number, block: ImageBlockModel) => {
    return (
      <ImageBlock
        isDragging={dragIndex >= 0}
        block={block}
        onBlockChange={(changedBlock) => updateBlock(index, changedBlock)}
      ></ImageBlock>
    );
  };

  const renderFAQBlock = (index: number, block: FAQBlockModel) => {
    return (
      <FAQBlock
        isDragging={dragIndex >= 0}
        block={block}
        onBlockChange={(changedBlock) => updateBlock(index, changedBlock)}
      ></FAQBlock>
    );
  };

  const renderBlock = (index: number, block: InfoBlockModel) => {
    if (block.type === blockTypes.INFO) return renderInfoBlock(index, block);
    if (block.type === blockTypes.IMAGE) return renderImageBlock(index, block);
    if (block.type === blockTypes.FAQ) return renderFAQBlock(index, block);
  };

  const onDrag = (ev: React.DragEvent<HTMLDivElement>, index: number) => {
    console.log("onDrag " + dragIndex);
    setDragIndex(index);
    ev.dataTransfer.setData("draggedIndex", index.toString());
    // ev.preventDefault();
  };

  const onDrop = (
    ev: React.DragEvent<HTMLDivElement>,
    droppedIndex: number
  ) => {
    ev.preventDefault();
    var draggedIndex = parseInt(ev.dataTransfer.getData("draggedIndex"));

    var updatedBlocks = [...props.blocks];
    updatedBlocks.splice(droppedIndex, 0, props.blocks[draggedIndex]);
    if (droppedIndex > draggedIndex) {
      updatedBlocks.splice(draggedIndex, 1);
    } else {
      //moved upward in the list
      updatedBlocks.splice(draggedIndex + 1, 1);
    }
    props.onBlockListChange(updatedBlocks);
  };

  const onDragOver = (
    ev: React.DragEvent<HTMLDivElement>,
    dragOverIndex: number
  ) => {
    ev.preventDefault();
    setDragOverIndex(dragOverIndex);
  };

  return (
    <Container isDragging={dragIndex >= 0}>
      {dragIndex >= 0 && dragIndex != 0 && (
        <DropTarget
          dragOver={0 === dragOverIndex}
          onDragOver={(ev) => onDragOver(ev, 0)}
          onDrop={(ev) => {
            onDrop(ev, 0);
          }}
        ></DropTarget>
      )}
      {props.blocks.map((block, index) => {
        return (
          <div>
            <DraggableWrapper
              onMouseEnter={() => setMouseOverIndex(index)}
              onMouseLeave={() => setMouseOverIndex(-1)}
              draggable
              onDragStart={(ev) => onDrag(ev, index)}
              onDragEnd={() => {
                setDragIndex(-1);
                setDragOverIndex(-1);
              }}
            >
              {index == mouseOverIndex && <DragZone></DragZone>}

              {renderBlock(index, block)}
              {index == mouseOverIndex && (
                <DeleteButtonContainer>
                  <RemoveButton
                    onRemoveTriggered={() => {
                      deleteBlock(index);
                    }}
                  ></RemoveButton>
                </DeleteButtonContainer>
              )}
            </DraggableWrapper>
            {dragIndex >= 0 && dragIndex != index && (
              <DropTarget
                dragOver={index == dragOverIndex - 1}
                onDragOver={(ev) => onDragOver(ev, index + 1)}
                onDrop={(ev) => {
                  onDrop(ev, index + 1);
                }}
              ></DropTarget>
            )}
          </div>
        );
      })}
      <BlockToolBar
        blockTypes={props.blockTypes}
        onAdd={(newBlock) => addNewBlock(newBlock)}
      ></BlockToolBar>
    </Container>
  );
}

import firebaseInstance from '../firebaseConfig/Firebase'

var db = firebaseInstance.firestore();


export const addToCollection = (collectionName: string, data: object, callback: (docRef: firebaseInstance.firestore.DocumentReference | null) => void) => {
      
    var dataWithTimeStamp = {...data, created: firebaseInstance.firestore.Timestamp.now() }
    db.collection(collectionName).add(dataWithTimeStamp).then(
        (docRef) => { callback(docRef)}).catch((error) => {
            callback(null);
        })
}

export const readDocumentById = (collectonName:string, id: string, callback: (document: any) => void) => {
    
    var docRef = db.collection(collectonName).doc(id);

    docRef.get().then((doc) => {
        if (doc.exists) {
            callback(doc.data());
        } else {
            callback(null)
        }
    }).catch((error) => {
        callback(null);
        console.log("Error getting document:", error);
    });
}

export const updateDocumentById = (collectionName: string, id: string, data: object, callback: (response:any) => void) => {
    db.collection(collectionName).doc(id).set(data
    ).then(() => {
        callback({message:"Successfully updated!"})
        console.log("Document successfully written!");
        })
        .catch((error) => {
            callback(null)
    });
}


export const deleteDocumentById = (collectionName: string, id: string, callback: (response:any) => void) => {
    db.collection(collectionName).doc(id).delete().then(() => {
        callback({message:"Successfully deleted!"})
        })
        .catch((error) => {
            callback(null)
    });
}


export const readAllDocuments = (collectionName: string, callback: (response:any) => void) => {
    
    var documents:any[] = [];
    db.collection(collectionName).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
        documents.push({...doc.data(),id:doc.id});
    });
        callback(documents)
}).catch((error) => {
            callback(null)
    });
}

export const readAllDocumentsOrderBy = (collectionName: string, orderBy:string, callback: (response:any) => void) => {
    
    var documents:any[] = [];
    db.collection(collectionName).orderBy(orderBy).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
        documents.push({...doc.data(),id:doc.id});
    });
        callback(documents)
}).catch((error) => {
            callback(null)
    });
}
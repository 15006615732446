import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { colorTheme, P } from "../../Styles";
import Button from "../Button";
import firebaseInstance from "../../../../firebaseConfig/Firebase";
import { UserContext } from "../../../../context/UserContext";
import { AlertContext } from "../../../../context/AlertContext";
import { animationKeyFrames } from "../../../../theme/keyframes";

const Container = styled.div`
  margin: 0;
  padding: 0;
`;
const HeaderBar = styled.div`
  width: 100%;
  /* height: 3vw; */
  background-color: ${colorTheme.lightGrey};
  margin: 0;
  padding: 0 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Alert = styled.div<{ height: number }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  width: 100%;
  height: ${(props) => props.height}vw;
  transition: height 0.5s;
  background-color: ${colorTheme.secondary};
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Header() {
  const Logout = () => {
    firebaseInstance
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const { user, setUser } = useContext(UserContext);

  const { alert, setAlert } = useContext(AlertContext);

  const [isAlertShowing, setIsAlertShowing] = useState(false);

  useEffect(() => {
    if (!isAlertShowing && alert.visible) {
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
        setIsAlertShowing(false);
      }, 2000);
      setIsAlertShowing(true);
    }
  });

  return (
    <Container>
      <HeaderBar>
        <P marginTop={1} strong>
          SWLC Web Admin Portal
        </P>
        {user && (
          <Button primary small text={"Logout"} onClick={Logout}></Button>
        )}
      </HeaderBar>
      <Alert height={alert.visible ? 6 : 0}>
        <P center strong color={colorTheme.black} marginTop={0}>
          {alert.message}
        </P>
      </Alert>
    </Container>
  );
}

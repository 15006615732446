import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { infoIDs, readInfoById } from "../../../api/Info";

import { LocationContext } from "../../../context/LocationContext";
import ServiceInfoModel from "../../../models/infoModels/ServiceInfoModel";
import { devices } from "../../../theme/devices";
import { colorTheme, HorizontalList, P, Section } from "../../../theme/Styles";
import FormattedAddress from "../formattedAddress/FormattedAddress";
import FormattedList from "../formattedList/FormattedList";
import FormattedP from "../formattedP/FormattedP";

import fbIcon from "./assets/fb.svg";
import linkedinIcon from "./assets/linkedin.svg";
import tiktokIcon from "./assets/tiktok.svg";
import yelpIcon from "./assets/yelp.svg";
import twitterIcon from "./assets/twitter.svg";
import AboutUsInfoModel from "../../../models/infoModels/AboutUsInfoModel";

const Container = styled.div`
  display: flex;
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;
const ContactInfo = styled.div`
  width: 40vw;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const Address = styled.div`
  margin-bottom: 3vw;

  @media ${devices.mobile} {
    margin-bottom: 10vw;
  }
`;

const SocialMedia = styled.div`
  margin-top: 3vw;
  @media ${devices.mobile} {
    margin-top: 10vw;
  }
`;

const SocialMediaIconContainer = styled.div`
  display: flex;
  img {
    width: 3vw;
    margin-top: 0.8vw;
    margin-right: 0.9vw;
    transition: transform 0.5s;
    padding: 0.2vw;

    @media ${devices.mobile} {
      padding: 1vw;

      width: 12vw;
      margin-top: 3vw;
      margin-right: 2vw;
    }

    &:hover {
      transform: scale(1.08);
    }
  }
`;

const Segment = styled.div`
  @media ${devices.mobile} {
    margin: 10vw 0;
  }
`;

export default function Footer() {
  const [serviceInfo, setServiceInfo] = useState<ServiceInfoModel>({});
  const [aboutInfo, setAboutInfo] = useState<AboutUsInfoModel>({});

  useEffect(() => {
    readInfoById(infoIDs.serviceInfo, (response) => {
      if (response) setServiceInfo(response);
    });
  }, []);

  useEffect(() => {
    readInfoById(infoIDs.aboutUsInfo, (response) => {
      if (response) setAboutInfo(response);
    });
  }, []);

  return (
    <Section backgroundColor={colorTheme.primary}>
      <Container>
        <ContactInfo>
          {serviceInfo.address && (
            <Address>
              <P strong marginTop={0.2} color={colorTheme.primaryLight}>
                Address
              </P>
              <FormattedAddress
                color={colorTheme.white}
                text={serviceInfo.address}
              ></FormattedAddress>
            </Address>
          )}

          <P marginTop={0.2} strong color={colorTheme.primaryLight}>
            Contact
          </P>
          {serviceInfo.phone && (
            <P color={colorTheme.white} marginTop={0.2}>
              {serviceInfo.phone}{" "}
            </P>
          )}
          {serviceInfo.email && (
            <P color={colorTheme.white} marginTop={0.2}>
              {serviceInfo.email}
            </P>
          )}
        </ContactInfo>

        {serviceInfo.hours && (
          <Segment>
            <P marginTop={0.2} strong color={colorTheme.primaryLight}>
              Hours of Operation
            </P>

            <FormattedList
              color={colorTheme.white}
              text={serviceInfo.hours}
            ></FormattedList>

            <SocialMedia>
              <P strong marginTop={0.2} color={colorTheme.primaryLight}>
                Follow Us
              </P>
              <SocialMediaIconContainer>
                {aboutInfo.facebookLink && (
                  <a target="_blank" href={aboutInfo.facebookLink}>
                    <img src={fbIcon}></img>
                  </a>
                )}
                {aboutInfo.twitterLink && (
                  <a target="_blank" href={aboutInfo.twitterLink}>
                    <img src={twitterIcon}></img>
                  </a>
                )}
                {aboutInfo.yelpLink && (
                  <a target="_blank" href={aboutInfo.yelpLink}>
                    <img src={yelpIcon}></img>
                  </a>
                )}
                {aboutInfo.tiktokLink && (
                  <a target="_blank" href={aboutInfo.tiktokLink}>
                    <img src={tiktokIcon}></img>
                  </a>
                )}
                {aboutInfo.linkedinLink && (
                  <a target="_blank" href={aboutInfo.linkedinLink}>
                    <img src={linkedinIcon}></img>
                  </a>
                )}
              </SocialMediaIconContainer>
            </SocialMedia>
          </Segment>
        )}
      </Container>
    </Section>
  );
}

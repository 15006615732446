import { createContext } from "react";

export const LocationContext = createContext<any>(null);

export const locationIDs = {
    home:"home",
    borrow: "borrow",
    donate: "donate",
    aboutus: "aboutus",
    events: "events",
    volunteer: "volunteer",
    equipment: "equipment",
    singleEvent: "singleEvent",




    
}

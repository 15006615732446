import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Body, Page } from "../../Styles";

import Card from "./Card";

import donateIcon from "./assets/donate.svg";
import equipmentIcon from "./assets/equipment.svg";
import eventsIcon from "./assets/events.svg";
import serviceIcon from "./assets/service_info.svg";
import aboutUsIcon from "./assets/about-us.svg";
import volunteerIcon from "./assets/volunteer.svg";
import homeIcon from "./assets/home.svg";

const cards = [
  { text: "Events", imgSrc: eventsIcon, link: "allevents" },
  { text: "Equipment", imgSrc: equipmentIcon, link: "equipment" },
  { text: "Borrow Page", imgSrc: serviceIcon, link: "serviceInfo" },
  {
    text: "Volunteer Page",
    imgSrc: volunteerIcon,
    link: "volunteerInfo",
  },
  { text: "Donate Page", imgSrc: donateIcon, link: "donations" },
  {
    text: "Home Page",
    imgSrc: homeIcon,
    link: "homeInfo",
  },
  { text: "About Us Page", imgSrc: aboutUsIcon, link: "aboutUs" },
];

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 5vw;
`;
export default function Dashboard(props: RouteComponentProps<any>) {
  return (
    <Page>
      <Body>
        <CardContainer>
          {cards.map((card, index) => (
            <Card
              small={index > 2}
              text={card.text}
              iconSrc={card.imgSrc}
              onClick={() => {
                props.history.push("/portal/" + card.link);
              }}
            ></Card>
          ))}
        </CardContainer>
      </Body>
    </Page>
  );
}

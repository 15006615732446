import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { LocationContext, locationIDs } from "../../context/LocationContext";

import { devices } from "../../theme/devices";
import { colorTheme } from "../../theme/Styles";
import AboutUs from "../pages/aboutus/AboutUs";
import Equipment from "../pages/equipment/Equipment";
import Events from "../pages/events/AllEvents";
import SingleEvent from "../pages/events/SingleEvent";
import Borrow from "../pages/borrow/Borrow";
import Donate from "../pages/donate/Donate";
import Home from "../pages/home/Home";
import Volunteer from "../pages/volunteer/Volunteer";
import Footer from "../ui/Footer/Footer";
import TopBar from "../ui/topBar/TopBar";

const TopBarGap = styled.div`
  width: 100%;
  height: 5vw;
  background-color: ${colorTheme.white};
  @media ${devices.mobile} {
    height: 20vw;
  }
`;

export default function WebPageRoutes() {
  const [location, setLocation] = useState(locationIDs.home);
  return (
    <>
      <LocationContext.Provider value={{ location, setLocation }}>
        <TopBar></TopBar>
        <TopBarGap></TopBarGap>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/borrow" component={Borrow} />
          <Route exact path="/volunteer" component={Volunteer} />
          <Route exact path="/donate" component={Donate} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/equipment" component={Equipment} />
          <Route exact path="/:id" component={SingleEvent} />
        </Switch>
        <Footer></Footer>
      </LocationContext.Provider>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../../context/UserContext";
import { getCurrentUser } from "../../firebaseAuth/FirebaseAuth";

import { devices } from "../../theme/devices";
import { colorTheme } from "../../theme/Styles";
import AboutUs from "../portal/pages/aboutUs/AboutUs";
import Dashboard from "../portal/pages/dashboard/Dashboard";
import Donations from "../portal/pages/donations/Donations";
import EditEquipment from "../portal/pages/equipment/EditEquipment";
import EquipmentList from "../portal/pages/equipment/EquipmentList";
import AllEvents from "../portal/pages/events/allEvents/AllEvents";
import CreateEvent from "../portal/pages/events/create/CreateEvent";
import Home from "../portal/pages/home/Home";
import Service from "../portal/pages/service/Service";
import SignIn from "../portal/pages/signIn/SignIn";
import Volunteers from "../portal/pages/volunteers/Volunteers";
import Header from "../portal/ui/header/Header";

import firebaseInstance from "../../firebaseConfig/Firebase";
import PrivateRoute from "./privateRoute/PrivateRoute";
import { AlertContext } from "../../context/AlertContext";

const TopBarGap = styled.div`
  width: 100%;
  height: 6vw;
  background-color: ${colorTheme.white};
  @media ${devices.mobile} {
    height: 20vw;
  }
`;

export default function PortalRoutes() {
  const [user, setUser] = useState(getCurrentUser());

  const [alert, setAlert] = useState({ visible: false, message: "" });

  useEffect(() => {
    firebaseInstance.auth().onAuthStateChanged((currentUser) => {
      console.log(JSON.stringify(currentUser));
      setUser(currentUser);
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <AlertContext.Provider value={{ alert, setAlert }}>
        <Header></Header>
        <Switch>
          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/createEvent/:id"
            exact
            component={CreateEvent}
          />
          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/allevents"
            exact
            component={AllEvents}
          />
          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/equipment"
            exact
            component={EquipmentList}
          />
          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/donations"
            exact
            component={Donations}
          />
          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/aboutUs"
            exact
            component={AboutUs}
          />
          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/serviceInfo"
            exact
            component={Service}
          />

          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/volunteerInfo"
            exact
            component={Volunteers}
          />

          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/homeInfo"
            exact
            component={Home}
          />

          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"../../portal/signIn"}
            path="/portal/editequipment/:id"
            exact
            component={EditEquipment}
          ></PrivateRoute>
          <Route path="/portal/signIn" exact component={SignIn} />

          <PrivateRoute
            isAuthenticated={user ? true : false}
            authenticationPath={"portal/signIn"}
            path="/portal"
            exact
            component={Dashboard}
          />
        </Switch>
      </AlertContext.Provider>
    </UserContext.Provider>
  );
}

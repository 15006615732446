import React from "react";
import { P } from "../../../theme/Styles";

export default function FormattedP(props: {
  text: string;
  center?: boolean;
  strong?: boolean;
  color?: string;
  width?: number;
  centerOnMobile?: boolean;
}) {
  return (
    <div>
      {props.text.split(/\r?\n/).map((s, index) => (
        <P
          strong={props.strong}
          center={props.center}
          color={props.color}
          width={props.width}
          centerOnMobile={props.centerOnMobile}
        >
          {s}
        </P>
      ))}
    </div>
  );
}

import styled from "styled-components";
import { devices } from "../../../theme/devices";
import { P, Section } from "../../../theme/Styles";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

export const HeroContainer = styled.div <{reverseOnMobile?:boolean}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media ${devices.mobile} {
    flex-direction:${props=>props.reverseOnMobile?"column-reverse":"column"};
  }
`;

export const HeroText = styled.div`
  width: 35vw;
  margin: 0 4vw;
   @media ${devices.mobile} {
    width:100%;
    padding:5vw 8vw;
  }
`;

export const HeroImageContainer = styled.div`
  width: 58vw;
  min-height:38vw;
  max-height:38vw;
  overflow:hidden;
  display:flex;
  align-items:center;
  justify-content: center;
   @media ${devices.mobile} {
    width:100%;
    max-height:none;
  }
`;

export const HeroImage = styled.img`
  width: 60vw;
  /* min-height:40vw; */
  height: auto;
   @media ${devices.mobile} {
    width:100%
  }
`;

export const FullWidthHeroImage = styled.img`
  width: 100%;
  height: auto;
   @media ${devices.mobile} {
    width:100%
  }
`;

export const Logo = styled.img`
  width: 12vw;
  height: auto;
  margin: 3vw 0;
`;

export const HeroSection = styled(Section)`
  width: 100%;
  padding: 0;
`;

export const HeroDescription = styled(P)`
  width: 23vw;
  margin:2.5vw 0;
   @media ${devices.mobile} {
    width:100%
  }
`;

export const HeroOverviewContainer = styled.div`
width: 24vw;
  margin:2.5vw 0;
   @media ${devices.mobile} {
    width:100%
  }
`;


export const HeroPrimaryButton = styled(PrimaryButton)`
 max-width:3vw;
`;

export const HeroSecondaryButton = styled(SecondaryButton)`
  min-width:3vw;
`;
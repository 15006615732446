import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { infoIDs, readInfoById, updateInfoById } from "../../../../api/Info";
import { AlertContext } from "../../../../context/AlertContext";
import AboutUsInfoModel from "../../../../models/infoModels/AboutUsInfoModel";
import DonationsInfoModel from "../../../../models/infoModels/DonationsInfoModel";
import {
  Body,
  H2,
  H3,
  HorizontalFlex,
  Link,
  Page,
  VerticalCushion,
  StickyHeader,
} from "../../Styles";
import Button from "../../ui/Button";
import BlockList from "../../ui/editables/editableBlocks/BlockList";
import EditableImage from "../../ui/editables/editableImage/EditableImage";
import EditableText from "../../ui/editables/editableText/EditableText";

export default function AboutUs() {
  const [aboutUsInfo, setAboutUsInfo] = useState<AboutUsInfoModel>({});

  const { alert, setAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);

  const update = () => {
    setLoading(true);
    updateInfoById(infoIDs.aboutUsInfo, aboutUsInfo, (response) => {
      readAboutUsInfo();
      setAlert({ visible: true, message: response.message });
      setLoading(false);
    });
  };

  useEffect(() => {
    readAboutUsInfo();
  }, []);

  const readAboutUsInfo = () => {
    readInfoById(infoIDs.aboutUsInfo, (response) => {
      if (response) setAboutUsInfo(response);
    });
  };

  return (
    <Page>
      <Body>
        <VerticalCushion small> </VerticalCushion>
        <Link href={"/portal"}>Dashboard</Link>{" "}
        <StickyHeader>
          <H2>About Us</H2>
          <Button
            loading={loading}
            primary
            text={"Update"}
            onClick={update}
          ></Button>
        </StickyHeader>
        <VerticalCushion small> </VerticalCushion>
        <EditableText
          label={"Our Mission"}
          width={60}
          rows={4}
          text={aboutUsInfo.mission || ""}
          placeholder={""}
          onChange={(text) => setAboutUsInfo({ ...aboutUsInfo, mission: text })}
        ></EditableText>
        <EditableText
          label={"Our Vision"}
          width={60}
          rows={6}
          text={aboutUsInfo.vision || ""}
          placeholder={""}
          onChange={(text) => setAboutUsInfo({ ...aboutUsInfo, vision: text })}
        ></EditableText>
        <EditableText
          label={"Our History"}
          width={60}
          rows={10}
          text={aboutUsInfo.history || ""}
          placeholder={""}
          onChange={(text) => setAboutUsInfo({ ...aboutUsInfo, history: text })}
        ></EditableText>
        <VerticalCushion small>
          <VerticalCushion small>
            <H3>Our Organization</H3>
          </VerticalCushion>

          <EditableText
            label={"Short Summary"}
            width={60}
            rows={4}
            text={aboutUsInfo.organizationSummery || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, organizationSummery: text })
            }
          ></EditableText>
          <EditableText
            label={"Executive Director"}
            width={40}
            rows={1}
            text={aboutUsInfo.executiveDirector || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, executiveDirector: text })
            }
          ></EditableText>

          <EditableText
            label={"Lead Volunteers"}
            width={80}
            rows={12}
            text={aboutUsInfo.leadVolunteers || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, leadVolunteers: text })
            }
          ></EditableText>

          <EditableText
            label={"Board of Directors"}
            width={80}
            rows={8}
            text={aboutUsInfo.boardOfDirectors || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, boardOfDirectors: text })
            }
          ></EditableText>

          <EditableText
            label={"Members"}
            width={60}
            rows={15}
            text={aboutUsInfo.members || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, members: text })
            }
          ></EditableText>
        </VerticalCushion>
        <VerticalCushion small>
          <VerticalCushion small>
            <H3>Video Tour</H3>
          </VerticalCushion>
          <EditableText
            label={"Youtube embed link to video tour"}
            width={70}
            rows={1}
            text={aboutUsInfo.videoTourLink || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, videoTourLink: text })
            }
          ></EditableText>
        </VerticalCushion>
        <VerticalCushion small>
          <VerticalCushion small>
            <H3>Social Media (Footer)</H3>
          </VerticalCushion>
          <EditableText
            label={"Link to Facebook"}
            width={70}
            rows={1}
            text={aboutUsInfo.facebookLink || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, facebookLink: text })
            }
          ></EditableText>
          <EditableText
            label={"Link to Twitter"}
            width={70}
            rows={1}
            text={aboutUsInfo.twitterLink || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, twitterLink: text })
            }
          ></EditableText>

          <EditableText
            label={"Link to Yelp"}
            width={70}
            rows={1}
            text={aboutUsInfo.yelpLink || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, yelpLink: text })
            }
          ></EditableText>

          <EditableText
            label={"Link to Tiktok"}
            width={70}
            rows={1}
            text={aboutUsInfo.tiktokLink || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, tiktokLink: text })
            }
          ></EditableText>

          <EditableText
            label={"Link to Instagram"}
            width={70}
            rows={1}
            text={aboutUsInfo.instagramLink || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, instagramLink: text })
            }
          ></EditableText>

          <EditableText
            label={"Link to Linkedin"}
            width={70}
            rows={1}
            text={aboutUsInfo.linkedinLink || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, linkedinLink: text })
            }
          ></EditableText>
        </VerticalCushion>
        <VerticalCushion small>
          <VerticalCushion small>
            <H3>Support System</H3>
          </VerticalCushion>
          <EditableText
            label={"Our Support System"}
            width={60}
            rows={15}
            text={aboutUsInfo.supportSystemText || ""}
            placeholder={""}
            onChange={(text) =>
              setAboutUsInfo({ ...aboutUsInfo, supportSystemText: text })
            }
          ></EditableText>
          <EditableImage
            width={"60vw"}
            src={aboutUsInfo.supportSystemImage || ""}
            onFileChange={(url) => {
              if (url != null)
                setAboutUsInfo({ ...aboutUsInfo, supportSystemImage: url });
            }}
          ></EditableImage>
        </VerticalCushion>
      </Body>
    </Page>
  );
}

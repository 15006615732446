import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { infoIDs, readInfoById, updateInfoById } from "../../../../api/Info";
import { blockTypes } from "../../../../constants/BlockTypes";
import { AlertContext } from "../../../../context/AlertContext";
import DonationsInfoModel from "../../../../models/infoModels/DonationsInfoModel";
import {
  Body,
  H2,
  H3,
  HorizontalFlex,
  Link,
  Page,
  StickyHeader,
  VerticalCushion,
} from "../../Styles";
import Button from "../../ui/Button";
import BlockList from "../../ui/editables/editableBlocks/BlockList";
import EditableText from "../../ui/editables/editableText/EditableText";

export default function Donations() {
  const [donations, setDonations] = useState<DonationsInfoModel>({});

  const { alert, setAlert } = useContext(AlertContext);

  const update = () => {
    updateInfoById(infoIDs.donationsInfo, donations, (response) => {
      readDonationsInfo();
      // alert(JSON.stringify(response));
      setAlert({ visible: true, message: response.message });
    });
  };

  useEffect(() => {
    readDonationsInfo();
  }, []);

  const readDonationsInfo = () => {
    readInfoById(infoIDs.donationsInfo, (response) => {
      if (response) setDonations(response);
    });
  };

  return (
    <Page>
      <Body>
        <VerticalCushion small> </VerticalCushion>
        <Link href={"/portal"}>Dashboard</Link>{" "}
        <StickyHeader>
          <H2>Donate Page</H2>
          <Button primary text={"Update"} onClick={update}></Button>
        </StickyHeader>
        <VerticalCushion small> </VerticalCushion>
        <EditableText
          label={"Page Overview"}
          width={60}
          rows={8}
          text={donations.overview || ""}
          placeholder={""}
          onChange={(text) => setDonations({ ...donations, overview: text })}
        ></EditableText>
        <EditableText
          label={"Call for donations "}
          width={60}
          rows={4}
          text={donations.callForDonations || ""}
          placeholder={""}
          onChange={(text) =>
            setDonations({ ...donations, callForDonations: text })
          }
        ></EditableText>
        <EditableText
          label={"Link to payment gateway"}
          width={60}
          rows={1}
          text={donations.paymentGatewayURL || ""}
          placeholder={"Link to payment portal"}
          onChange={(text) =>
            setDonations({ ...donations, paymentGatewayURL: text })
          }
        ></EditableText>
        <EditableText
          label={"Address for checks"}
          rows={5}
          text={donations.address || ""}
          placeholder={"Address to send checks"}
          onChange={(text) => setDonations({ ...donations, address: text })}
        ></EditableText>
        <VerticalCushion>
          <H3>Other ways to help us</H3>
          <BlockList
            infoBlockImageRemovable
            blockTypes={[blockTypes.INFO]}
            blocks={donations.otherMethods || []}
            onBlockListChange={(blocks) =>
              setDonations({ ...donations, otherMethods: blocks })
            }
          ></BlockList>
        </VerticalCushion>
      </Body>
    </Page>
  );
}

import React from "react";
import styled from "styled-components";
import { ButtonContainer, colorTheme } from "../../../theme/Styles";

const Container = styled(ButtonContainer)<{
  backgroundColor?: string;
  color?: string;
}>`
  background-color: ${(props) => props.backgroundColor || colorTheme.primary};
  color: ${(props) => props.color || colorTheme.white};
  border: 2px solid ${(props) => props.backgroundColor || colorTheme.primary};
`;

export default function PrimaryButton(props: {
  onClick: () => void;
  text: string;
  color?: string;
  backgroundColor?: string;
}) {
  return (
    <Container
      color={props.color}
      backgroundColor={props.backgroundColor}
      onClick={() => props.onClick()}
    >
      {props.text}
    </Container>
  );
}

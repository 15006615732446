import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { devices } from "../../../theme/devices";
import {
  ButtonList,
  colorTheme,
  H1,
  H2,
  P,
  Section,
} from "../../../theme/Styles";
import ProcessImage from "../../processImage/ProcessImage";
import PrimaryButton from "../../ui/buttons/PrimaryButton";
import SecondaryButton from "../../ui/buttons/SecondaryButton";
import CircleImage from "../../ui/circleIImage/CircleImage";
import {
  HeroContainer,
  HeroDescription,
  HeroImage,
  HeroImageContainer,
  HeroPrimaryButton,
  HeroSecondaryButton,
  HeroSection,
  HeroText,
} from "../../ui/heroComponents/HeroStyledComponents";
import doanteImage from "./assets/donate_homepage.jpg";
import volunteerImage from "./assets/volunteer_homepage.png";

import heroImage from "./assets/home_hero.jpg";
import FeaturedEvent from "./FeaturedEvent";
import HomePageInfoModel from "../../../models/infoModels/HomePageInfoModel";
import { infoIDs, readInfoById } from "../../../api/Info";
import FormattedP from "../../ui/formattedP/FormattedP";
import LoadingPage from "../../ui/loadingPage/LoadingPage";

const VolunteerSection = styled(Section)`
  @media ${devices.mobile} {
    padding: 0 0;
  }
`;

const VolunteerBody = styled.div`
  @media ${devices.mobile} {
    padding: 7vw;
  }
`;

const AboutSection = styled(Section)`
  margin-top: 3vw;
  margin-bottom: 5vw;
  h1 {
    line-height: 1.3;
  }

  @media ${devices.mobile} {
    padding: 0 6vw;
  }
`;

const AboutText = styled(H1)`
  width: 55vw;
  @media ${devices.mobile} {
    font-size: 10vw;
    width: 100%;
  }
`;

const ProcessTextContainer = styled.div`
  width: 34vw;
  margin: auto;
  margin-bottom: 3vw;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const VolunteerOverviewContainer = styled.div`
  width: 34vw;
  margin: auto;
  margin-bottom: 3vw;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const DonateImageCaption = styled(H2)`
  position: absolute;
  bottom: -0.6vw;
  left: 0vw;
  padding: 0.5vw 8vw;
  width: 100%;
  font-size: 0.9vw;
  font-weight: 500;
  line-height: 1.4vw;
  background-color: #ffffff73;

  @media ${devices.mobile} {
    position: relative;
    padding: 3vw 5vw;
    width: 100%;
    font-size: 3vw;
    line-height: 4vw;
    margin: 0;
    background-color: none;
  }
`;

const DonateImageSuperContainer = styled.div`
  position: relative;
`;

export default function Home(props: RouteComponentProps<any>) {
  const [HomePageInfo, setHomePageInfo] = useState<HomePageInfoModel | null>(
    null
  );

  // const { location, setLocation } = useContext(LocationContext);

  // useEffect(() => {
  //   setLocation(locationIDs.aboutus);
  // }, []);

  useEffect(() => {
    readInfoById(infoIDs.homeInfo, (response) => {
      if (response) setHomePageInfo(response);
    });
  }, []);

  if (!HomePageInfo) return <LoadingPage></LoadingPage>;

  return (
    <div>
      <HeroSection backgroundColor={colorTheme.primary}>
        <HeroContainer reverseOnMobile>
          <HeroText>
            {HomePageInfo.tagLine && (
              <H1 color={colorTheme.primaryLight}>{HomePageInfo.tagLine}</H1>
            )}
            {HomePageInfo.mainOverview && (
              <HeroDescription color={colorTheme.white}>
                {HomePageInfo.mainOverview}
              </HeroDescription>
            )}
            <ButtonList smallBottomMargin>
              <HeroPrimaryButton
                color={colorTheme.primary}
                backgroundColor={colorTheme.primaryLight}
                onClick={() => {
                  props.history.push("/borrow");
                  window.scrollTo(0, 0);
                }}
                text={"borrow equipment"}
              ></HeroPrimaryButton>
              <HeroSecondaryButton
                color={colorTheme.primaryLight}
                backgroundColor={colorTheme.primary}
                onClick={() => {
                  props.history.push("/volunteer");
                  window.scrollTo(0, 0);
                }}
                text={"volunteer"}
              ></HeroSecondaryButton>
              <HeroSecondaryButton
                color={colorTheme.primaryLight}
                backgroundColor={colorTheme.primary}
                onClick={() => {
                  props.history.push("/donate");
                  window.scrollTo(0, 0);
                }}
                text={"donate"}
              ></HeroSecondaryButton>
            </ButtonList>
          </HeroText>
          <HeroImageContainer>
            <HeroImage src={heroImage}></HeroImage>
          </HeroImageContainer>
        </HeroContainer>
      </HeroSection>

      <FeaturedEvent></FeaturedEvent>

      <Section>
        <H2 center color={colorTheme.secondary}>
          How to borrow{" "}
        </H2>

        {HomePageInfo.borrowOverview && (
          <ProcessTextContainer>
            <FormattedP center text={HomePageInfo.borrowOverview}></FormattedP>
          </ProcessTextContainer>
        )}

        <ProcessImage></ProcessImage>
        <ButtonList smallTopMargin center>
          <SecondaryButton
            backgroundColor={colorTheme.white}
            onClick={() => {
              props.history.push("/borrow");
              window.scrollTo(0, 0);
            }}
            text={"Learn more about borrowing"}
          ></SecondaryButton>
          <PrimaryButton
            onClick={() => {
              props.history.push("/equipment");
              window.scrollTo(0, 0);
            }}
            text={"view equipment list"}
          ></PrimaryButton>
        </ButtonList>
      </Section>
      <VolunteerSection backgroundColor={colorTheme.primary}>
        <CircleImage src={volunteerImage}></CircleImage>
        <VolunteerBody>
          <H2 color={colorTheme.primaryLight} center>
            Volunteer with us
          </H2>
          {HomePageInfo.volunteerOverview && (
            <VolunteerOverviewContainer>
              <FormattedP
                color={colorTheme.white}
                center
                text={HomePageInfo.volunteerOverview}
              ></FormattedP>
            </VolunteerOverviewContainer>
          )}

          <ButtonList center>
            <SecondaryButton
              color={colorTheme.primaryLight}
              backgroundColor={colorTheme.primary}
              text={"how to volunteer"}
              onClick={() => {
                props.history.push("/volunteer");
                window.scrollTo(0, 0);
              }}
            ></SecondaryButton>
          </ButtonList>
        </VolunteerBody>
      </VolunteerSection>

      <HeroSection backgroundColor={colorTheme.extraLightGrey}>
        <HeroContainer>
          <DonateImageSuperContainer>
            <HeroImageContainer>
              <HeroImage src={doanteImage}></HeroImage>
            </HeroImageContainer>
            <DonateImageCaption color={colorTheme.black}>
              The new SWLC Thank You Card above shows a Campanula, commonly
              known as the Bellflower. The Bellflower is symbolic of gratitude.
            </DonateImageCaption>
          </DonateImageSuperContainer>
          <HeroText>
            <H2 color={colorTheme.secondary}>Donate</H2>
            {HomePageInfo.donateOverview && (
              <FormattedP
                text={HomePageInfo.donateOverview}
                centerOnMobile
                width={25}
              ></FormattedP>
            )}
            <ButtonList smallTopMargin smallBottomMargin>
              <SecondaryButton
                backgroundColor={colorTheme.extraLightGrey}
                text={"how to Donate"}
                onClick={() => {
                  props.history.push("/donate");
                  window.scrollTo(0, 0);
                }}
              ></SecondaryButton>
            </ButtonList>
          </HeroText>
        </HeroContainer>
      </HeroSection>

      <AboutSection>
        {HomePageInfo.aboutUsOverview && (
          <AboutText>{HomePageInfo.aboutUsOverview}</AboutText>
        )}

        <ButtonList>
          <SecondaryButton
            text={"More about us"}
            onClick={() => {
              props.history.push("/aboutus");
              window.scrollTo(0, 0);
            }}
          ></SecondaryButton>
        </ButtonList>
      </AboutSection>
    </div>
  );
}
